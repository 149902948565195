import { Button, LoadingOverlay, TextInput } from "@mantine/core";
import { useParams } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import useSeasonCreatePage, { useSeasonCreatePageData } from "./useSeasonCreatePage";

const SeasonCreate: React.FC = () =>
{
  const { leagueId } = useParams();

  const pageData: useSeasonCreatePageData = useSeasonCreatePage({ leagueId });

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">CREATE SEASON</p>
      <TextInput
        placeholder="Season name"
        label="Season name"
        value={pageData.seasonName}
        onChange={(event) => pageData.handleNameUpdate(event.currentTarget.value)}
        required
      />

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCreate}
        >
          Create
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>

    </div>
  )
}

export default SeasonCreate;