import { GameType } from "./GameType";
import { CompetitorReference } from "./CompetitorReference";
import IRecordType from "./IRecordType";
import { GameEvent } from "./GameEvent";

enum GameState
{
  SCHEDULED = "SCHEDULED",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  FORFEIT = "FORFEIT",
  POSTPONED = "POSTPONED"
}

interface Game extends IRecordType
{
  id?: string,
  gameId: string,
  leagueId: string,
  divisionId: string,
  poolId: string,
  seasonId: string,
  competitorOne: CompetitorReference,
  competitorTwo: CompetitorReference,
  competitorOneScore: number,
  competitorTwoScore: number,
  competitorOnePlayers: string[],
  competitorOneGoalies: string[],
  competitorTwoPlayers: string[],
  competitorTwoGoalies: string[],
  locationId: string
  gameState: GameState,
  gameType: GameType,
  time: string,
  events?: GameEvent[]
}

export default Game;
export { GameState };
