import { Button, Grid, Paper, ScrollArea, Text } from "@mantine/core"
import { GameEvent, GameEventType, GameGoalForEvent, GamePenaltyEvent, GameShotForEvent } from "../../types/GameEvent"
import { Delete } from "@mui/icons-material"
import Player from "../../types/Player"

export interface GameLogProps
{
  events: GameEvent[],
  players: Player[],
  deleteGameEvent(eventId: number): void,
}

interface DeleteEventButtonProps
{
  eventId: number,
  deleteGameEvent(eventId: number): void
}

interface EventCardProps
{
  event: GameEvent,
  lookupPlayer(playerId: string): Player,
  deleteGameEvent(eventId: number): void
}

interface CardDetailProps
{
  event: GameEvent,
  lookupPlayer(playerId: string): Player,
}

const EventCard = (props: EventCardProps) =>
{
  return (
    <Paper shadow="sm" p="md" withBorder>
      <Grid>
        <Grid.Col span={2}>
          <Text>{props.event.type}</Text>
        </Grid.Col>
        <Grid.Col span={5}>
          {props.event.type === GameEventType.SHOT && <ShotCard {...props} />}
          {props.event.type === GameEventType.GOAL && <GoalCard {...props} />}
          {props.event.type === GameEventType.PENALTY && <PenaltyCard {...props} />}
        </Grid.Col>
        <Grid.Col span="content" offset={3}>
          <DeleteEventButton deleteGameEvent={props.deleteGameEvent} eventId={props.event.eventId} />
        </Grid.Col>
      </Grid>
    </Paper>
  )
}

const DeleteEventButton = (props: DeleteEventButtonProps) =>
{
  return (
    <Button variant="default" onClick={() => props.deleteGameEvent(props.eventId)}>
      <Delete />
    </Button>
  )
}

const ShotCard = (props: CardDetailProps) =>
{
  const event = props.event as GameShotForEvent;
  const goalie = props.lookupPlayer(event.goalieId ?? "");

  return (
    <>
      {event.goalieId && <Text size="xs">Goalie: {`${goalie.firstName} ${goalie.surname}`}</Text>}
    </>
  )
}

const GoalCard = (props: CardDetailProps) =>
{
  const event = props.event as GameGoalForEvent;
  const goalie = props.lookupPlayer(event.goalieId ?? "");
  const scorer = props.lookupPlayer(event.playerId ?? "");
  const assistNames = event.assistIds?.map(playerId => 
  {
    if (!playerId || playerId === "") return;

    const player = props.lookupPlayer(playerId);

    return `${player.firstName} ${player.surname}`;
  }) ?? [];

  return (
    <>
      {event.goalieId && <Text size="xs">Goalie: {`${goalie.firstName} ${goalie.surname}`}</Text>}
      {event.playerId && <Text size="xs">Scorer: {`${scorer.firstName} ${scorer.surname}`}</Text>}
      {assistNames.map(a => a && <Text size="xs" key={a}>Assist: {a}</Text>)}
    </>
  )
}

const PenaltyCard = (props: CardDetailProps) =>
{
  const event = props.event as GamePenaltyEvent;
  const player = props.lookupPlayer(event.playerId ?? "");
  const penalty = event.penaltyId;

  return (
    <>
      <Text size="xs">Penalty: {`${penalty}`}</Text>
      {event.playerId && <Text size="xs">Player: {`${player.firstName} ${player.surname}`}</Text>}
    </>
  )
}

const lookupPlayer = (playerId: string, players: Player[]): Player =>
{
  const player = players.find(p => p.id === playerId);

  return player ?? {
    firstName: "Unknown",
    leagueId: "",
    surname: ""
  }
}

const GameLog: React.FC<GameLogProps> = (props: GameLogProps) =>
{
  return (
    <>
      <Paper shadow="xl" p="lg" withBorder>
        <ScrollArea style={{ height: 500 }}>
          {props.events.map(e => <EventCard
            key={e.eventId.toString()}
            event={e}
            deleteGameEvent={props.deleteGameEvent}
            lookupPlayer={(playerId: string) => lookupPlayer(playerId, props.players)}
          />)}
        </ScrollArea>
      </Paper>
    </>
  )
}

export default GameLog;