import { Route, Routes } from "react-router-dom";
import Games from "../components/Games/Games";
import GameEdit from "../components/GameEdit/GameEdit";
import Players from "../components/Players/Players";
import Competitors from "../components/Competitors/Competitors";
import Divisions from "../components/Divisions/Divisions";
import Pools from "../components/Pools/Pools";
import Seasons from "../components/Seasons/Seasons";
import Logos from "../components/Logos/Logos";
import Locations from "../components/Locations/Locations";
import CompetitorCreate from "../components/CompetitorCreate/CompetitorCreate";
import CompetitorEdit from "../components/CompetitorEdit/CompetitorEdit";
import DivisionCreate from "../components/DivisionCreate/DivisionCreate";
import DivisionEdit from "../components/DivisionEdit/DivisionEdit";
import PoolCreate from "../components/PoolCreate/PoolCreate";
import PoolEdit from "../components/PoolEdit/PoolEdit";
import SeasonCreate from "../components/SeasonCreate/SeasonCreate";
import SeasonEdit from "../components/SeasonEdit/SeasonEdit";
import LogoCreate from "../components/LogoCreate/LogoCreate";
import LogoEdit from "../components/LogoEdit/LogoEdit";
import LocationCreate from "../components/LocationCreate/LocationCreate";
import LocationEdit from "../components/LocationEdit/LocationEdit";
import PlayerCreate from "../components/PlayerCreate/PlayerCreate";
import GameImport from "../components/GameImport/GameImport";
import GameCreate from "../components/GameCreate/GameCreate";

function AppRoutes() 
{
  return (
      <Routes>
        <Route path="/" element={<Games />} />

        <Route path="/Players" element={<Players />} />
        <Route path="/league/:leagueId/player/create" element={<PlayerCreate />} />

        <Route path="/Teams" element={<Competitors />} />
        <Route path="/league/:leagueId/season/:seasonId/team/create" element={<CompetitorCreate />} />
        <Route path="/league/:leagueId/season/:seasonId/team/:competitorId/edit" element={<CompetitorEdit />} />

        <Route path="/Games" element={<Games />} />
        <Route path="/league/:leagueId/season/:seasonId/games" element={<Games />} />
        <Route path="/league/:leagueId/season/:seasonId/game/:gameId/edit" element={<GameEdit />} />
        <Route path="/league/:leagueId/season/:seasonId/game/create" element={<GameCreate />} />
        <Route path="/league/:leagueId/season/:seasonId/game/import" element={<GameImport />} />

        <Route path="/Divisions" element={<Divisions />} />
        <Route path="/league/:leagueId/season/:seasonId/division/create" element={<DivisionCreate />} />
        <Route path="/league/:leagueId/season/:seasonId/division/:divisionId/edit" element={<DivisionEdit />} />

        <Route path="/Pools" element={<Pools />} />
        <Route path="/league/:leagueId/season/:seasonId/pool/create" element={<PoolCreate />} />
        <Route path="/league/:leagueId/season/:seasonId/pool/:poolId/edit" element={<PoolEdit />} />

        <Route path="/Seasons" element={<Seasons />} />
        <Route path="/league/:leagueId/season/create" element={<SeasonCreate />} />
        <Route path="/league/:leagueId/season/:seasonId/edit" element={<SeasonEdit />} />

        <Route path="/Logos" element={<Logos />} />
        <Route path="/league/:leagueId/logo/create" element={<LogoCreate />} />
        <Route path="/league/:leagueId/logo/:logoId/edit" element={<LogoEdit />} />

        <Route path="/Locations" element={<Locations />} />
        <Route path="/league/:leagueId/location/create" element={<LocationCreate />} />
        <Route path="/league/:leagueId/location/:locationId/edit" element={<LocationEdit />} />
      </Routes>
  )
}

export default AppRoutes;