import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyBTUwpX30G0la2s4Pwq9YkDjFENqnEbYfs",
  authDomain: "scorebench-dev.firebaseapp.com",
  projectId: "scorebench-dev",
  storageBucket: "scorebench-dev.appspot.com",
  messagingSenderId: "856616467039",
  appId: "1:856616467039:web:878d00a9888a1bcec0b9ea",
  measurementId: "G-7GRX04MW7X"
};

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app);