import { useState, useRef, useEffect } from "react";
import { leagueService } from "../../services/LeagueService";
import { logoService } from "../../services/LogoService";
import League from "../../types/League";
import Logo from "../../types/Logo";
import RequestStatus from "../../types/RequestStatus";
import { handleRowSelect } from "../../utils/handlers";
import { useNavigate } from "react-router-dom";

export interface UseLogosPageData
{
  logos: Logo[],
  requestStatus: RequestStatus,
  selectedLogos: string[],
  displayDeleteModal: boolean,
  handleLogoSelect(logoId: string): void,
  handleClearSelection(): void,
  handleCreateClick(): void,
  handleEditClick(logo: Logo): void,
  deleteSelectedLogos(): Promise<void>,
  showDeleteModal(): void,
  closeDeleteModal(): void,
  isSelected(logoId: string): boolean
}

const useLogosPage = (): UseLogosPageData =>
{
  const [logos, setLogos] = useState<Logo[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [selectedLogos, setSelectedLogos] = useState<string[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);

  const league = useRef<League | null>(null);

  const navigate = useNavigate();

  const handleClearSelection = () => setSelectedLogos([]);
  const handleCreateClick = () => navigate(`/league/${league.current?.id}/logo/create`);
  const handleEditClick = (logo: Logo) => navigate(`/league/${league.current?.id}/logo/${logo.id}/edit`);
  const showDeleteModal = () => setDisplayDeleteModal(true);
  const closeDeleteModal = () => setDisplayDeleteModal(false);
  const isSelected = (logoId: string) => selectedLogos.includes(logoId);

  const fetchLogos = async () =>
  {
    if (!league.current)
    {
      return;
    }

    const logosData = await logoService.getLogos(league.current.id);

    setLogos(logosData);
  }

  const deleteSelectedLogos = async () =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      if (league.current?.id && selectedLogos.length > 0)
      {
        await logoService.deleteLogos(league.current.id, selectedLogos);

        setDisplayDeleteModal(false);

        await fetchLogos();

        setSelectedLogos([]);
      }

      setRequestStatus(RequestStatus.SUCCESS);
    }
    catch
    {
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  // Initialisation on load
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        const leagueData = await leagueService.getLeague();
        league.current = leagueData;

        await fetchLogos();

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, [])

  return {
    logos,
    requestStatus,
    selectedLogos,
    displayDeleteModal,
    handleLogoSelect: (logoId: string) => handleRowSelect(logoId, selectedLogos, setSelectedLogos),
    handleClearSelection,
    handleCreateClick,
    handleEditClick,
    deleteSelectedLogos,
    showDeleteModal,
    closeDeleteModal,
    isSelected
  }
}

export default useLogosPage;