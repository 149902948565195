import { Button, Grid, LoadingOverlay, Select, TextInput } from "@mantine/core";
import useCompetitorCreatePage, { UseCompetitorCreatePageData } from "./useCompetitorCreatePage";
import { useParams } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import AddPlayerDialog from "../CompetitorEdit/AddPlayerDialog";
import { DialogButton } from "../DialogButton/DialogButton";
import { PlayerTable } from "../PlayerTable/PlayerTable";

const CompetitorCreate: React.FC = () =>
{
  const { leagueId, seasonId } = useParams();

  const pageData: UseCompetitorCreatePageData = useCompetitorCreatePage({ leagueId, seasonId });

  return (
    <div className="dataWindow">
      <Grid>
        <Grid.Col span={4}>
          <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
          <p className="title">CREATE TEAM</p>
          <TextInput
            placeholder="Team name"
            label="Team name"
            value={pageData.competitorName}
            onChange={(event) => pageData.handleNameUpdate(event.currentTarget.value)}
            required
          />

          <Select
            placeholder="Division"
            label="Division"
            data={pageData.divisionOptions}
            value={pageData.divisionId}
            onChange={pageData.handleDivisionUpdate}
            required
            searchable
          />

          <Select
            placeholder="Pool"
            label="Pool"
            data={pageData.poolOptions}
            value={pageData.poolId}
            onChange={pageData.handlePoolUpdate}
            disabled={pageData.poolOptions.length === 0}
            clearable
          />

          <Select
            placeholder="Logo"
            label="Logo"
            data={pageData.logoOptions}
            value={pageData.logoId}
            onChange={pageData.handleLogoUpdate}
            disabled={pageData.logoOptions.length === 0}
            clearable
            searchable
          />

          <p className="text-2xl mt-10 mb-5">Players</p>
          <Grid>
            <Grid.Col span="content">
              <DialogButton label="Add player">
                <AddPlayerDialog
                  addPlayerHandler={pageData.handleAddPlayer}
                  playerOptions={pageData.playerOptions}
                />
              </DialogButton>
            </Grid.Col>
            <Grid.Col span="content">
              <Button
                variant="default"
                onClick={pageData.handleRemovePlayers}
              >
                Remove players
              </Button>
            </Grid.Col>
          </Grid>
          <PlayerTable players={pageData.getCompetitorPlayers()} handleRemovePlayer={pageData.handleRemovePlayer} />
        </Grid.Col>
      </Grid>

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCreate}
        >
          Create
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>

    </div >
  )
}

export default CompetitorCreate;