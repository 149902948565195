import Division from "../types/Division";
import { DivisionOptions } from "../types/DivisionOptions";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { deleteApiData, getApiData, patchApiData, postApiData } from "../utils/scorebenchApi";

class DivisionService
{
  private divisions: Division[] = [];

  getDivisions = async (leagueId: string, seasonId: string, forceRefresh?: boolean): Promise<Division[]> =>
  {
    if (this.divisions.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/season/${seasonId}/division`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as Division[]

      this.divisions = records;
    }

    return this.divisions;
  }

  getDivision = async (leagueId: string, seasonId: string, divisionId: string): Promise<Division | null> =>
  {
    const targetDivision = (await this.getDivisions(leagueId, seasonId))
      .find(d => d.id === divisionId)

    return targetDivision ?? null;
  }

  getDivisionOptions = async (leagueId: string, seasonId: string, forceRefresh?: boolean): Promise<DivisionOptions[]> =>
  {
    return (await this.getDivisions(leagueId, seasonId, forceRefresh))
      .filter((division) => division.seasonId === seasonId)
      .map((division) =>
      {
        return {
          value: division.id,
          label: division.name
        }
      })
  }

  createDivision = async (newDivision: Division) =>
  {
    const apiSuffix = `league/${newDivision.leagueId}/season/${newDivision.seasonId}/division`

    await postApiData(apiSuffix, newDivision);

    this.divisions = [];
  }

  updateDivision = async (updatedDivision: Division) =>
  {
    const apiSuffix = `league/${updatedDivision.leagueId}/season/${updatedDivision.seasonId}/division`

    await patchApiData(apiSuffix, updatedDivision);

    this.divisions = [];
  }

  deleteDivision = async (leagueId: string, seasonId: string, divisionId: string): Promise<void> =>
  {
    const apiSuffix = `league/${leagueId}/season/${seasonId}/division/${divisionId}`

    await deleteApiData(apiSuffix);

    this.divisions = [];
  }

  deleteDivisions = async (leagueId: string, seasonId: string, divisionIds: string[]): Promise<void> =>
  {
    await Promise.all(divisionIds.map(async (divisionId: string) => await this.deleteDivision(leagueId, seasonId, divisionId)))
  }
}

export const divisionService = new DivisionService();