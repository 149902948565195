// These will be reworked later. For now player data will not be used.

import { Button, Checkbox, LoadingOverlay } from "@mantine/core";
import { Edit } from "@mui/icons-material";
import IPlayer from "../../types/Player";
import RequestStatus from "../../types/RequestStatus";
import { Table, TableBody, TableDataCell, TableDataRow, TableHead, TableHeadCell, TableHeadRow } from "../TableComponents/TableComponents";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import usePlayersPage from "./usePlayersPage";

const Players = () =>
{
  const pageData = usePlayersPage();

  return (
    <div className="dataWindow">

      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />

      <p className="title">PLAYERS</p>
      <div className="searchToolbar">
        <Button
          variant="default"
          className="ml-4 mb-4"
          onClick={pageData.handleCreateClick}
        >
          Create
        </Button>

        <Button
          variant="default"
          disabled={pageData.selectedPlayers.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.handleClearSelection}
        >
          Clear selection
        </Button>

        <Button
          variant="default"
          disabled={pageData.selectedPlayers.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.showDeleteModal}
        >
          Delete selected
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell />
            <TableHeadCell>First Name</TableHeadCell>
            <TableHeadCell>Surname</TableHeadCell>
            <TableHeadCell>Number</TableHeadCell>
            <TableHeadCell>Position</TableHeadCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {pageData.players.map((player: IPlayer) => (
            <TableDataRow key={player.id}>
              <TableDataCell>
                <Checkbox
                  checked={pageData.selectedPlayers.includes(player.id ?? "")}
                  onChange={() => pageData.handlePlayerSelect(player.id ?? "")}
                />
              </TableDataCell>
              <TableDataCell>{player.firstName}</TableDataCell>
              <TableDataCell>{player.surname}</TableDataCell>
              <TableDataCell>{player.playerNumber}</TableDataCell>
              <TableDataCell>{player.position}</TableDataCell>
              <TableDataCell>
                <Button
                  variant="light"
                // onClick={() => pageData.handleEditClick(player)}
                >
                  <Edit />
                </Button>
              </TableDataCell>
            </TableDataRow>
          ))}
        </TableBody>
      </Table>

      {pageData.displayDeleteModal &&
        <DeleteConfirmation
          opened={pageData.displayDeleteModal}
          handleDelete={pageData.deleteSelectedPlayers}
          handleClose={pageData.closeDeleteModal}
        />}
    </div >
  )
}

export default Players;
