import { Center, Image, Navbar, Stack } from "@mantine/core";
import { PersonOutline, PeopleOutline, ScoreboardOutlined, ViewHeadlineOutlined, CalendarMonthOutlined, SportsHockeyOutlined, StadiumOutlined, Apps, LogoutOutlined, WorkspacesOutlined } from "@mui/icons-material";
import { useContext } from "react";
import { AuthContext, IAuthContext } from "../../contexts/AuthContext";
import SideBarLink, { ISideBarLinkProps } from "../SideBarLink/SideBarLink";

import styles from "./SideBar.module.css"

const links: ISideBarLinkProps[] = [
  {
    linkIcon: (<PersonOutline fontSize="large" />),
    linkText: "Players",
    linkUrl: "/Players"
  },
  {
    linkIcon: (<PeopleOutline fontSize="large" />),
    linkText: "Teams",
    linkUrl: "/Teams"
  },
  {
    linkIcon: (<ScoreboardOutlined fontSize="large" />),
    linkText: "Games",
    linkUrl: "/Games"
  },
  {
    linkIcon: (<ViewHeadlineOutlined fontSize="large" />),
    linkText: "Divisions",
    linkUrl: "/Divisions"
  },
  {
    linkIcon: (<WorkspacesOutlined fontSize="large" />),
    linkText: "Pools",
    linkUrl: "/Pools"
  },
  {
    linkIcon: (<CalendarMonthOutlined fontSize="large" />),
    linkText: "Seasons",
    linkUrl: "/Seasons"
  },
  {
    linkIcon: (<StadiumOutlined fontSize="large" />),
    linkText: "Locations",
    linkUrl: "/Locations"
  },
  {
    linkIcon: (<Apps fontSize="large" />),
    linkText: "Logos",
    linkUrl: "/Logos"
  },
  {
    linkIcon: (<SportsHockeyOutlined fontSize="large" />),
    linkText: "League",
    linkUrl: "/League"
  }
]

const SideBar = () =>
{
  const authData: IAuthContext = useContext(AuthContext);

  const linkStack = links.map((link, index) => <SideBarLink key={link.linkUrl} {...link} />)

  return (
    <Navbar width={{ base: 80 }} p="md" fixed withBorder>
      <Navbar.Section>
        <Center>
          <Image src="/S.png" withPlaceholder className={styles.sidebarLogo} />
        </Center>
      </Navbar.Section>
      <Navbar.Section>
        <Stack className="h-screen" mt={50} spacing={0}>
          <div className="align-top">
            {linkStack}
          </div>

          <SideBarLink
            linkIcon={<LogoutOutlined fontSize="large" />}
            linkText="Logout"
            linkAction={authData.signOut}
          />

        </Stack>
      </Navbar.Section>
    </Navbar>
  )
}

export default SideBar;
