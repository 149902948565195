export enum CompetitorType
{
  ID = "ID",
  TEXT = "TEXT"
}

export interface CompetitorReference
{
  type: CompetitorType
}

export interface CompetitorIdReference extends CompetitorReference
{
  type: CompetitorType.ID,
  id: string
}

export interface CompetitorTextReference extends CompetitorReference
{
  type: CompetitorType.TEXT,
  text: string
}