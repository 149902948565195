import { useState } from "react";
import { competitorService } from "../services/CompetitorService";
import Competitor from "../types/Competitor";
import { CompetitorIdReference, CompetitorReference, CompetitorTextReference, CompetitorType } from "../types/CompetitorReference";
import useLeague from "./useLeague";

export interface UseCompetitorListData
{
  competitors: Competitor[],
  refreshCompetitors(seasonId: string): Promise<void>
  getCompetitor(competitorId?: string): Competitor
  getCompetitorReferenceText(competitorReference: CompetitorReference): string
}

const useCompetitors = (): UseCompetitorListData =>
{
  const leagueData = useLeague();

  const [competitors, setCompetitors] = useState<Competitor[]>([]);

  const getCompetitor = (competitorId?: string): Competitor =>
  {
    const targetCompetitor = competitors.find(c => c.id === competitorId)

    if (!targetCompetitor)
    {
      return {
        id: "",
        divisionId: "",
        leagueId: "",
        logoId: "",
        name: "Unknown",
        poolId: "",
        seasonId: ""
      }
    }

    return targetCompetitor;
  };

  const getCompetitorReferenceText = (competitorReference: CompetitorReference): string =>
  {
    if (competitorReference.type === CompetitorType.ID)
    {
      const competitorId = (competitorReference as CompetitorIdReference).id;

      return competitors.find(c => c.id === competitorId)?.name ?? "Unknown";
    }

    return (competitorReference as CompetitorTextReference).text;
  }

  const refreshCompetitors = async (seasonId: string) =>
  {
    const league = await leagueData.refreshLeague();

    if (!seasonId)
    {
      return;
    }

    const competitorData = await competitorService.getCompetitors(league.id, seasonId, true);

    setCompetitors(competitorData);
  }

  return {
    competitors,
    refreshCompetitors,
    getCompetitor,
    getCompetitorReferenceText
  }
}

export default useCompetitors;