import { useEffect, useState } from "react"
import RequestStatus from "../../types/RequestStatus"
import useCompetitors from "../../hooks/useCompetitors"
import useDivisions from "../../hooks/useDivisions"
import useLocations from "../../hooks/useLocations"
import { gameService } from "../../services/GameService"
import Game, { GameState } from "../../types/Game"
import { CompetitorIdReference, CompetitorType } from "../../types/CompetitorReference"
import { GameType } from "../../types/GameType"
import { divisionService } from "../../services/DivisionService"
import { DivisionOptions } from "../../types/DivisionOptions"
import { useNavigate } from "react-router-dom"
import { CompetitorOptions } from "../../types/CompetitorOptions"
import { competitorService } from "../../services/CompetitorService"
import { LocationOptions } from "../../types/LocationOptions"
import { locationService } from "../../services/LocationService"

export interface UseGameCreateProps
{
  leagueId: string
  seasonId: string
}

export interface UseGameCreateData
{
  requestStatus: RequestStatus
  error: string | null
  gameId: string
  gameTime: Date
  locationId: string
  divisionId: string
  competitorOneId: string
  competitorTwoId: string
  competitorOptions: CompetitorOptions[]
  divisionOptions: DivisionOptions[]
  locationOptions: LocationOptions[]
  handleSetGameId(id: string): void
  handleSetGameDate(date: Date): void
  handleSetGameTime(time: Date): void
  handleSetLocationId(id: string | null): void
  handleSetDivisionId(id: string | null): void
  handleSetCompetitorOneId(competitorId: string | null): void
  handleSetCompetitorTwoId(competitorId: string | null): void
  handleGameCreate(): Promise<void>
  handleCancel(): void
}

function useGameCreate(props: UseGameCreateProps): UseGameCreateData
{
  const navigate = useNavigate();

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [error, setError] = useState<string | null>(null);

  const [gameId, setGameId] = useState("");
  const [gameTime, setGameTime] = useState(new Date());
  const [locationId, setLocationId] = useState("");
  const [divisionId, setDivisionId] = useState("");
  const [competitorOneId, setCompetitorOneId] = useState("");
  const [competitorTwoId, setCompetitorTwoId] = useState("");

  const [divisionOptions, setDivisionOptions] = useState<DivisionOptions[]>([]);
  const [competitorOptions, setCompetitorOptions] = useState<CompetitorOptions[]>([]);
  const [locationOptions, setLocationOptions] = useState<LocationOptions[]>([]);

  const locationData = useLocations();
  const divisionData = useDivisions();
  const competitorData = useCompetitors();

  const handleSetGameId = (id: string) => setGameId(id);
  const handleSetLocationId = (id: string) => setLocationId(id);
  const handleSetDivisionId = (id: string) => setDivisionId(id);
  const handleSetCompetitorOneId = (competitorId: string) => setCompetitorOneId(competitorId);
  const handleSetCompetitorTwoId = (competitorId: string) => setCompetitorTwoId(competitorId);

  const handleSetGameDate = (date: Date) =>
  {
    const timeValue = gameTime
      ? new Date(gameTime.valueOf())
      : new Date();

    timeValue.setFullYear(date.getFullYear());
    timeValue.setMonth(date.getMonth());
    timeValue.setDate(date.getDate());

    setGameTime(timeValue);
  }

  const handleSetGameTime = (time: Date) =>
  {
    const dateValue = gameTime
      ? new Date(gameTime.valueOf())
      : new Date();

    dateValue.setHours(time.getHours());
    dateValue.setMinutes(time.getMinutes());

    setGameTime(dateValue);
  }

  const handleCancel = () => navigate("/Games");

  const handleGameCreate = async () =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      if (!props.leagueId || !props.seasonId)
      {
        setError("League and season are required");
        setRequestStatus(RequestStatus.FAILURE);

        return;
      }

      if (!competitorOneId || !competitorTwoId)
      {
        setError("Competitors are required");
        setRequestStatus(RequestStatus.FAILURE);

        return;
      }

      const competitorOne: CompetitorIdReference = {
        type: CompetitorType.ID,
        id: competitorOneId
      }

      const competitorTwo: CompetitorIdReference = {
        type: CompetitorType.ID,
        id: competitorTwoId
      }

      const gameData: Game = {
        gameId,
        leagueId: props.leagueId,
        seasonId: props.seasonId,
        competitorOne,
        competitorTwo,
        competitorOneScore: 0,
        competitorTwoScore: 0,
        competitorOnePlayers: [],
        competitorOneGoalies: [],
        competitorTwoPlayers: [],
        competitorTwoGoalies: [],
        divisionId,
        gameState: GameState.SCHEDULED,
        gameType: GameType.REGULAR,
        locationId,
        poolId: "",
        time: gameTime.toISOString(),
        events: []
      }

      await gameService.createGame(gameData);

      navigate("/");

      setRequestStatus(RequestStatus.SUCCESS);
      setError(null);
    }
    catch
    {
      setRequestStatus(RequestStatus.FAILURE);

      setError("Failed to create game");
    }
  }

  // Initialize page on load
  useEffect(() => 
  {
    const initialize = async () => 
    {
      if (!props.leagueId || !props.seasonId)
      {
        return;
      }

      await competitorData.refreshCompetitors(props.seasonId);
      await divisionData.refreshDivisions(props.seasonId);
      await locationData.refreshLocations();

      const divisionOptionsData = await divisionService.getDivisionOptions(props.leagueId, props.seasonId, true);
      const competitorOptionsData = await competitorService.getCompetitorOptions(props.leagueId, props.seasonId);
      const locationOptionsData = await locationService.getLocationOptions(props.leagueId);

      setDivisionOptions(divisionOptionsData);
      setCompetitorOptions(competitorOptionsData);
      setLocationOptions(locationOptionsData);

      setRequestStatus(RequestStatus.SUCCESS);
    }

    initialize();
  }, [props.leagueId, props.seasonId]);

  return {
    requestStatus,
    error,
    gameId,
    gameTime,
    locationId,
    divisionId,
    competitorOneId,
    competitorTwoId,
    competitorOptions,
    divisionOptions,
    locationOptions,
    handleSetGameId,
    handleSetGameDate,
    handleSetGameTime,
    handleSetLocationId,
    handleSetDivisionId,
    handleSetCompetitorOneId,
    handleSetCompetitorTwoId,
    handleGameCreate,
    handleCancel
  }
}

export default useGameCreate;