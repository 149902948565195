import { Button, Select } from "@mantine/core";
import { PlayerOption } from "../../types/PlayerOptions";
import { useState } from "react";

interface SetGoalieDialogProps
{
  playerOptions: PlayerOption[],
  setGoalieHandler(playerId: string): void,
  goalieId?: string
}

const SetGoalieDialog = (props: SetGoalieDialogProps) =>
{
  const [playerId, setPlayerId] = useState<string>(props.goalieId ?? "");

  return (
    <>
      <Select
        data={props.playerOptions}
        searchable
        clearable
        className="mb-4"
        value={playerId}
        onChange={(e) => setPlayerId(e ?? "")}
      />
      <Button
        variant="default"
        onClick={() => props.setGoalieHandler(playerId)}
      >
        Set
      </Button>
    </>
  )
}

export default SetGoalieDialog;