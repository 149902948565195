import { useEffect, useRef, useState } from "react";
import Competitor from "../../types/Competitor";
import RequestStatus from "../../types/RequestStatus";
import { SeasonOption } from "../../types/SeasonOption";
import { competitorService } from "../../services/CompetitorService";
import { leagueService } from "../../services/LeagueService";
import { seasonService } from "../../services/SeasonService";
import League from "../../types/League";
import Season from "../../types/Season";
import { handleRowSelect } from "../../utils/handlers";
import { useNavigate } from "react-router-dom";
import { divisionService } from "../../services/DivisionService";
import Division from "../../types/Division";
import { poolService } from "../../services/PoolService";
import Pool from "../../types/Pool";

export interface UseCompetitorsPageData
{
  competitors: Competitor[],
  currentSeason: string,
  selectedCompetitors: string[],
  seasonOptions: SeasonOption[],
  requestStatus: RequestStatus,
  displayDeleteModal: boolean,
  getDivisonName(divisionId: string): string,
  getPoolName(poolId: string): string,
  handleSeasonSelect(seasonId: string | null): void,
  handleCompetitorSelect(competitorId: string): void,
  handleSelectAll(): void,
  handleClearSelection(): void,
  handleCreateClick(): void,
  handleEditClick(competitor: Competitor): void,
  deleteSelectedCompetitors(): Promise<void>,
  showDeleteModal(): void,
  closeDeleteModal(): void,
  isSelected(competitorId: string): boolean
}

const useCompetitorPage = (): UseCompetitorsPageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [competitors, setCompetitors] = useState<Competitor[]>([]);
  const [currentSeason, setCurrentSeason] = useState<string>("");
  const [selectedCompetitors, setSelectedCompetitors] = useState<string[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
  const [seasonOptions, setSeasonOptions] = useState<SeasonOption[]>([]);

  const league = useRef<League | null>(null);
  const seasons = useRef<Season[]>([]);
  const divisions = useRef<Division[]>([]);
  const pools = useRef<Pool[]>([]);

  const navigate = useNavigate();

  const handleSeasonSelect = (seasonId: string | null) => setCurrentSeason(seasonId ?? "")
  const handleSelectAll = () => setSelectedCompetitors(competitors.map(c => c.id));
  const handleClearSelection = () => setSelectedCompetitors([]);
  const handleCreateClick = () => navigate(`/league/${league.current?.id}/season/${currentSeason}/team/create`);
  const handleEditClick = (competitor: Competitor) => navigate(`/league/${league.current?.id}/season/${currentSeason}/team/${competitor.id}/edit`);
  const showDeleteModal = () => setDisplayDeleteModal(true);
  const closeDeleteModal = () => setDisplayDeleteModal(false);
  const isSelected = (competitorId: string) => selectedCompetitors.includes(competitorId);

  const getDivisonName = (divisionId: string) => divisions.current.find(d => d.id === divisionId)?.name ?? "Unknown";

  const getPoolName = (poolId: string) => 
  {
    if (!poolId)
    {
      return "";
    }

    return pools.current.find(p => p.id === poolId)?.name ?? "Unknown"
  };

  const fetchDivisions = async (leagueId: string, seasonId: string) =>
  {
    const divisionData = await divisionService.getDivisions(leagueId, seasonId, true);

    divisions.current = divisionData;
  }

  const fetchPools = async (leagueId: string, seasonId: string) =>
  {
    const poolsData = await poolService.getPools(leagueId, seasonId, true);

    pools.current = poolsData;
  }

  const fetchCompetitors = async (leagueId: string, seasonId: string, forceRefresh?: boolean) =>
  {
    const competitorData = await competitorService.getCompetitors(leagueId, seasonId, forceRefresh);

    setCompetitors(competitorData);
  }

  const fetchSeasonOptions = async (leagueId: string) =>
  {
    const seasonOptionData = await seasonService.getSeasonOptions(leagueId);

    setSeasonOptions(seasonOptionData);
  }

  const deleteSelectedCompetitors = async () =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      if (league.current?.id && currentSeason && selectedCompetitors.length > 0)
      {
        await competitorService.deleteCompetitors(league.current.id, currentSeason, selectedCompetitors);

        setDisplayDeleteModal(false);

        await fetchCompetitors(league.current.id, currentSeason, true);

        setSelectedCompetitors([]);
      }

      setRequestStatus(RequestStatus.SUCCESS);
    }
    catch
    {
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  // Update competitor data when season changes
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (currentSeason && league.current?.id)
        {
          await fetchCompetitors(league.current.id, currentSeason, true);

          await fetchDivisions(league.current.id, currentSeason);
          await fetchPools(league.current.id, currentSeason);

          setSelectedCompetitors([]);
        }

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, [currentSeason])

  // Initialisation on page load
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        const leagueData = await leagueService.getLeague();

        league.current = leagueData;

        if (leagueData)
        {
          const seasonData = await seasonService.getSeasons(leagueData.id);

          seasons.current = seasonData;

          setCurrentSeason(leagueData.defaultSeasonId ?? "");

          await fetchSeasonOptions(leagueData.id);
        }
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, []);

  return {
    competitors,
    currentSeason,
    selectedCompetitors,
    requestStatus,
    displayDeleteModal,
    getDivisonName,
    getPoolName,
    handleSeasonSelect,
    handleCompetitorSelect: (competitorId: string) => handleRowSelect(competitorId, selectedCompetitors, setSelectedCompetitors),
    handleSelectAll,
    handleClearSelection,
    handleCreateClick,
    handleEditClick,
    deleteSelectedCompetitors,
    showDeleteModal,
    closeDeleteModal,
    isSelected,
    seasonOptions
  }
}

export default useCompetitorPage;