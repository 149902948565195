import { useEffect, useState } from "react"
import { DivisionOptions } from "../../types/DivisionOptions";
import { divisionService } from "../../services/DivisionService";
import { poolService } from "../../services/PoolService";
import RequestStatus from "../../types/RequestStatus";
import { useNavigate } from "react-router-dom";
import Pool from "../../types/Pool";

export interface usePoolCreatePageData
{
  poolName: string,
  divisionId: string,
  divisionOptions: DivisionOptions[],
  requestStatus: RequestStatus,
  handleDivisionUpdate(divisionId: string): void,
  handleNameUpdate(name: string): void,
  handleCreate(): void,
  handleCancel(): void
}

export interface usePoolCreatePageProps
{
  leagueId?: string,
  seasonId?: string
}

const usePoolCreatePage = (props: usePoolCreatePageProps): usePoolCreatePageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [poolName, setPoolName] = useState("");
  const [divisionId, setDivisionId] = useState("");

  const [divisionOptions, setDivisionOptions] = useState<DivisionOptions[]>([]);

  const handleNameUpdate = (name: string) => setPoolName(name);

  const navigate = useNavigate();

  const handleCancel = () => navigate("/Pools");

  const handleCreate = () =>
  {
    const createPool = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId || !props.seasonId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const newPool: Pool = {
          id: "",
          name: poolName,
          divisionId,
          leagueId: props.leagueId,
          seasonId: props.seasonId,
        }

        await poolService.createPool(newPool);

        navigate("/Pools");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    createPool();
  }

  const handleDivisionUpdate = (divisionId: string) =>
  {
    setDivisionId(divisionId);
  }

  const fetchDivisions = async () =>
  {
    if (!props.leagueId || !props.seasonId)
    {
      return
    }

    const divisionOptionsData = await divisionService.getDivisionOptions(props.leagueId, props.seasonId, true);

    setDivisionOptions(divisionOptionsData);
  }

  // Initialise data on load
  useEffect(() =>
  {
    const initData = async () =>
    {
      try
      {
        if (!props.leagueId || !props.seasonId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setRequestStatus(RequestStatus.LOADING);

        await fetchDivisions();

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    initData();
  }, [])

  return {
    poolName,
    divisionId,
    divisionOptions,
    requestStatus,
    handleDivisionUpdate,
    handleNameUpdate,
    handleCreate,
    handleCancel
  }
}

export default usePoolCreatePage;