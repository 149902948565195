export type EventWithCompetitorId = GameShotForEvent
  | GameGoalForEvent
  | GamePenaltyEvent
  | GameCompetitorTimeoutEvent;

export enum GameEventType
{
  "SHOT" = "SHOT",
  "GOAL" = "GOAL",
  "PENALTY" = "PENALTY",
  "COMPETITORTIMEOUT" = "COMPETITORTIMEOUT",
  "REFEREETIMEOUT" = "REFEREETIMEOUT"
}

export interface GameEvent
{
  type: GameEventType,
  eventId: number,
  gamePeriod?: number,
  gameMinute?: number,
  gameSecond?: number,
}

export interface GameShotForEvent extends GameEvent
{
  type: GameEventType.SHOT,
  competitorId: string,
  goalieId?: string
}

export interface GameGoalForEvent extends GameEvent
{
  type: GameEventType.GOAL,
  competitorId: string,
  playerId?: string,
  assistIds?: string[],
  goalieId?: string,
}

export interface GamePenaltyEvent extends GameEvent
{
  type: GameEventType.PENALTY,
  penaltyId: string,
  competitorId: string,
  playerId?: string
}

export interface GameCompetitorTimeoutEvent extends GameEvent
{
  type: GameEventType.COMPETITORTIMEOUT
  competitorId: string
}

export interface GameRefereeTimeoutEvent extends GameEvent
{
  type: GameEventType.REFEREETIMEOUT
}