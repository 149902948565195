import { useEffect, useState } from "react";
import { parse } from "papaparse";
import RequestStatus from "../../types/RequestStatus";
import { IImportGame } from "../../types/IImportGame";
import { gameService } from "../../services/GameService";

export interface UseGameImportProps
{
  leagueId: string,
  seasonId: string
}

export interface UseGameImportData
{
  leagueId: string,
  seasonId: string,
  importFile: File | null,
  createResources: boolean,
  updateExisting: boolean,
  gamesToImport: IImportGame[],
  validationMessages: string[],
  setImportFile: React.Dispatch<React.SetStateAction<File | null>>,
  handleCreateResourceSwitch(event: any): void,
  handleUpdateExistingSwitch(event: any): void,
  validateGames(): void,
  importGames(): void,
  requestStatus: RequestStatus,
  error: string
}

const useGameImport = (props: UseGameImportProps): UseGameImportData =>
{
  const [importFile, setImportFile] = useState<File | null>(null);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [gamesToImport, setGamesToImport] = useState<IImportGame[]>([]);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const [createResources, setCreateResources] = useState(false);
  const [updateExisting, setUpdateExisting] = useState(true);

  const handleCreateResourceSwitch = (event: any) =>
  {
    setCreateResources(event.currentTarget.checked);
  }

  const handleUpdateExistingSwitch = (event: any) =>
  {
    setUpdateExisting(event.currentTarget.checked);
  }

  const validateGames = () =>
  {
    const uploadGames = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        setValidationMessages([]);

        const validationResults = await gameService.validateImportGames(props.leagueId, props.seasonId, gamesToImport, createResources);

        setValidationMessages(validationResults.errors);

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch (e: any)
      {
        console.log(e);

        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    uploadGames();
  }

  const importGames = () =>
  {
    const uploadGames = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        setValidationMessages([]);

        await gameService.importGames(props.leagueId, props.seasonId, gamesToImport, createResources, updateExisting);

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch (e: any)
      {
        console.log(e);

        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    uploadGames();
  }

  useEffect(() =>
  {
    if (!importFile) return;

    setRequestStatus(RequestStatus.LOADING);

    parse(importFile, {
      header: true,
      skipEmptyLines: true,
      complete(results: any)
      {
        setGamesToImport(results.data as IImportGame[]);

        setRequestStatus(RequestStatus.SUCCESS);
      },
    });
  }, [importFile])

  return {
    leagueId: props.leagueId,
    seasonId: props.seasonId,
    importFile,
    createResources,
    updateExisting,
    gamesToImport,
    validationMessages,
    setImportFile,
    handleCreateResourceSwitch,
    handleUpdateExistingSwitch,
    validateGames,
    importGames,
    requestStatus,
    error: ""
  }
}

export default useGameImport;