import { Button, Checkbox, LoadingOverlay } from "@mantine/core";
import { Edit } from "@mui/icons-material";
import Logo from "../../types/Logo";
import { LOGOSIZE } from "../../types/LogoSize";
import RequestStatus from "../../types/RequestStatus";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import { Table, TableBody, TableDataCell, TableDataRow, TableHead, TableHeadCell, TableHeadRow } from "../TableComponents/TableComponents";
import useLogosPage, { UseLogosPageData } from "./useLogosPage";
import CompetitorLogo from "../CompetitorLogo/CompetitorLogo";

const Logos = () =>
{
  const pageData: UseLogosPageData = useLogosPage();

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">LOGOS</p>
      <div className="searchToolbar">
        <Button
          variant="default"
          className="ml-4 mb-4"
          onClick={pageData.handleCreateClick}
        >
          Create
        </Button>

        <Button
          variant="default"
          disabled={pageData.selectedLogos.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.handleClearSelection}
        >
          Clear selection
        </Button>

        <Button
          variant="default"
          disabled={pageData.selectedLogos.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.showDeleteModal}
        >
          Delete selected
        </Button>

      </div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell />
            <TableHeadCell />
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {pageData.logos.map((logo: Logo) => (
            <TableDataRow key={logo.id}>
              <TableDataCell>
                <Checkbox
                  checked={pageData.selectedLogos.includes(logo.id)}
                  onChange={() => pageData.handleLogoSelect(logo.id)}
                />
              </TableDataCell>
              <TableDataCell>
                <CompetitorLogo logo={logo} size={LOGOSIZE.MEDIUM} />
              </TableDataCell>
              <TableDataCell>{logo.name}</TableDataCell>
              <TableDataCell>
                <Button
                  variant="light"
                  onClick={() => pageData.handleEditClick(logo)}
                >
                  <Edit />
                </Button>
              </TableDataCell>
            </TableDataRow>
          ))}
        </TableBody>
      </Table>

      {pageData.displayDeleteModal &&
        <DeleteConfirmation
          opened={pageData.displayDeleteModal}
          handleDelete={pageData.deleteSelectedLogos}
          handleClose={pageData.closeDeleteModal}
        />}
    </div>
  )
}

export default Logos;
