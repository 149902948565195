import { useState } from 'react';
import { createStyles, Table, ScrollArea, Button } from '@mantine/core';
import { Delete } from '@mui/icons-material';
import Player from '../../types/Player';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `rem solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
        }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

export interface PlayerTableProps
{
  players: Player[],
  handleRemovePlayer(playerId: string): void,
  height?: number
}

export function PlayerTable(props: PlayerTableProps)
{
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);

  const rows = props.players.map((row) => (
    <tr key={row.id}>
      <td>{`${row.firstName} ${row.surname}`}</td>
      <td>{row.playerNumber}</td>
      <td>{row.position}</td>
      <td>
        <Button variant="default" onClick={() => props.handleRemovePlayer(row.id ?? "")}>
          <Delete />
        </Button>
      </td>
    </tr>
  ));

  return (
    <ScrollArea style={{ height: props.height ?? 300 }} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
      <Table style={{ minWidth: 300 }}>
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            <th>Name</th>
            <th>Number</th>
            <th>Position</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}