import { useEffect, useRef, useState } from "react"
import Competitor from "../../types/Competitor"
import Logo from "../../types/Logo"
import RequestStatus from "../../types/RequestStatus"
import League from "../../types/League"
import { leagueService } from "../../services/LeagueService"
import { logoService } from "../../services/LogoService"

export interface UseCompetitorLogoProps
{
  competitor?: Competitor
  logo?: Logo
}

export interface UseCompetitorLogoData
{
  logo: Logo | null,
  caption: string,
  requestStatus: RequestStatus
}

export const useCompetitorLogo = (props: UseCompetitorLogoProps) =>
{
  const [logo, setLogo] = useState<Logo | null>(null);
  const [caption, setCaption] = useState<string>("");
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);

  const league = useRef<League | null>(null);

  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (props.logo)
        {
          setCaption(props.logo.name);
          setLogo(props.logo);

          setRequestStatus(RequestStatus.SUCCESS);

          return;
        }

        const leagueData = await leagueService.getLeague();

        if (!leagueData)
        {
          throw `League not found`;
        }

        league.current = leagueData;

        if (!props.competitor)
        {
          setRequestStatus(RequestStatus.SUCCESS);

          return;
        }

        const logoId = props.competitor?.logoId

        if (logoId)
        {
          const logoData = await logoService.getLogo(leagueData.id, logoId);

          setLogo(logoData);
        }
        else
        {
          setCaption(props.competitor.name);
        }

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    updateData();
  }, [props.competitor])

  return {
    logo,
    caption,
    requestStatus
  }
}