import { useEffect, useRef, useState } from "react";
import RequestStatus from "../../types/RequestStatus";
import { leagueService } from "../../services/LeagueService";
import League from "../../types/League";
import Player from "../../types/Player";
import { playerService } from "../../services/PlayerService";
import { handleRowSelect } from "../../utils/handlers";
import { useNavigate } from "react-router-dom";

export interface UsePlayersPageData
{
  players: Player[],
  requestStatus: RequestStatus,
  selectedPlayers: string[],
  displayDeleteModal: boolean,
  handlePlayerSelect(playerId: string): void,
  handleClearSelection(): void,
  handleCreateClick(): void,
  deleteSelectedPlayers(): Promise<void>,
  showDeleteModal(): void,
  closeDeleteModal(): void,
  isSelected(playerId: string): boolean
}

const usePlayersPage = (): UsePlayersPageData =>
{
  const [players, setPlayers] = useState<Player[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [selectedPlayers, setSelectedPlayers] = useState<string[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);

  const league = useRef<League | null>(null);

  const navigate = useNavigate();

  const handleClearSelection = () => setSelectedPlayers([]);
  const handleCreateClick = () => navigate(`/league/${league.current?.id}/player/create`);
  const showDeleteModal = () => setDisplayDeleteModal(true);
  const closeDeleteModal = () => setDisplayDeleteModal(false);
  const isSelected = (playerId: string) => selectedPlayers.includes(playerId);

  const fetchPlayers = async () =>
  {
    if (!league.current)
    {
      return;
    }

    const playerData = await playerService.getPlayers(league.current.id);

    setPlayers(playerData);
  }

  const deleteSelectedPlayers = async () =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      if (league.current?.id && selectedPlayers.length > 0)
      {
        await playerService.deletePlayers(league.current.id, selectedPlayers);

        setDisplayDeleteModal(false);

        await fetchPlayers();

        setSelectedPlayers([]);
      }

      setRequestStatus(RequestStatus.SUCCESS);
    }
    catch
    {
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  // Initialisation on load
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        const leagueData = await leagueService.getLeague();
        league.current = leagueData;

        await fetchPlayers();

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, [])

  return {
    players,
    requestStatus,
    selectedPlayers,
    displayDeleteModal,
    handlePlayerSelect: (playerId: string) => handleRowSelect(playerId, selectedPlayers, setSelectedPlayers),
    handleClearSelection,
    handleCreateClick,
    deleteSelectedPlayers,
    showDeleteModal,
    closeDeleteModal,
    isSelected
  }
}

export default usePlayersPage;