import { useState } from "react";
import Location from "../types/Location";
import { locationService } from "../services/LocationService";
import useLeague from "./useLeague";

export interface UseLocationsData
{
  locations: Location[],
  getLocation(locationId: string): Location | undefined,
  getLocationText(locationId: string): string,
  refreshLocations(): Promise<void>
}

const useLocations = (): UseLocationsData =>
{
  const leagueData = useLeague();

  const [locations, setLocations] = useState<Location[]>([]);

  const getLocation = (locationId: string) => locations.find(l => l.id === locationId);

  const getLocationText = (locationId: string) => getLocation(locationId)?.name ?? "Unknown";

  const refreshLocations = async () =>
  {
    const league = await leagueData.refreshLeague();

    const locationsData = await locationService.getLocations(league.id, true);

    setLocations(locationsData);
  }

  return {
    locations,
    getLocation,
    getLocationText,
    refreshLocations
  }
}

export default useLocations;