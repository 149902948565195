import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { deleteApiData, getApiData, patchApiData, postApiData } from "../utils/scorebenchApi";
import { LocationOptions } from "../types/LocationOptions";
import Location from "../types/Location";

class LocationService
{
  private locations: Location[] = [];

  getLocations = async (leagueId: string, forceRefresh?: boolean): Promise<Location[]> =>
  {
    if (this.locations.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/location`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as Location[]

      this.locations = records;
    }

    return this.locations;
  }

  getLocation = async (leagueId: string, locationId: string): Promise<Location | null> =>
  {
    const targetLocation = (await this.getLocations(leagueId))
      .find(l => l.id === locationId)

    return targetLocation ?? null;
  }

  createLocation = async (newLocation: Location) =>
  {
    const apiSuffix = `league/${newLocation.leagueId}/location`

    await postApiData(apiSuffix, newLocation);

    this.locations = [];
  }

  updateLocation = async (updatedLocation: Location) =>
  {
    const apiSuffix = `league/${updatedLocation.leagueId}/location`

    await patchApiData(apiSuffix, updatedLocation);

    this.locations = [];
  }

  deleteLocation = async (leagueId: string, locationId: string): Promise<void> =>
  {
    const apiSuffix = `league/${leagueId}/location/${locationId}`

    await deleteApiData(apiSuffix);

    this.locations = [];
  }

  deleteLocations = async (leagueId: string, locationIds: string[]): Promise<void> =>
  {
    await Promise.all(locationIds.map(async (locationId: string) => await this.deleteLocation(leagueId, locationId)))
  }

  getLocationOptions = async (leagueId: string, forceRefresh?: boolean): Promise<LocationOptions[]> =>
  {
    return (await this.getLocations(leagueId, forceRefresh))
      .map((location) =>
      {
        return {
          value: location.id,
          label: location.name
        }
      });
  }
}

export const locationService = new LocationService();