import { Button, Select } from "@mantine/core";
import { useState } from "react"
import { PlayerOption } from "../../types/PlayerOptions";
import { getPenaltyOptions } from "../../types/Penalties";

export interface PenaltyDialogProps
{
  playerOptions: PlayerOption[],
  goalieId?: string,
  createPenaltyEventHandler(penaltyId: string, playerId?: string): void
}

const PenaltyDialog: React.FC<PenaltyDialogProps> = (props: PenaltyDialogProps) =>
{
  const [playerId, setPlayerId] = useState("");
  const [penaltyId, setPenaltyId] = useState("");

  return (
    <>
      <Select
        label="Penalty"
        data={getPenaltyOptions()}
        searchable
        clearable
        className="mb-4"
        value={penaltyId}
        onChange={(e) => setPenaltyId(e ?? "")}
      />
      <Select
        label="Player"
        data={props.playerOptions}
        searchable
        clearable
        className="mb-4"
        value={playerId}
        onChange={(e) => setPlayerId(e ?? "")}
      />
      <Button
        variant="default"
        onClick={() => props.createPenaltyEventHandler(penaltyId, playerId)}
      >
        Add
      </Button>
    </>
  )
}

export default PenaltyDialog;