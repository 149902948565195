import { useState } from "react";
import Division from "../types/Division";
import { divisionService } from "../services/DivisionService";
import useLeague from "./useLeague";

export interface UseDivisionsData
{
  divisions: Division[],
  getDivision(divisionId: string): Division | undefined,
  getDivisionText(divisionId: string): string,
  refreshDivisions(seasonId: string): Promise<void>
}

const useDivisions = (): UseDivisionsData =>
{
  const leagueData = useLeague();

  const [divisions, setDivisions] = useState<Division[]>([]);

  const getDivision = (divisionId: string) => divisions.find(d => d.id === divisionId);

  const getDivisionText = (divisionId: string) => getDivision(divisionId)?.name ?? "Unknown";

  const refreshDivisions = async (seasonId: string) =>
  {
    const league = await leagueData.refreshLeague();

    if (!seasonId)
    {
      return;
    }
    const divisionData = await divisionService.getDivisions(league.id, seasonId, true);

    setDivisions(divisionData);
  }

  return {
    divisions,
    getDivision,
    getDivisionText,
    refreshDivisions
  }
}

export default useDivisions;