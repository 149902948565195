import { Button, LoadingOverlay, TextInput } from "@mantine/core";
import { useParams } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import useLocationCreatePage, { UseLocationCreatePageData } from "./useLocationCreatePage";

const LocationCreate: React.FC = () =>
{
  const { leagueId } = useParams();

  const pageData: UseLocationCreatePageData = useLocationCreatePage({ leagueId });

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">CREATE LOCATION</p>
      <TextInput
        placeholder="Location name"
        label="Location name"
        value={pageData.locationName}
        onChange={(event) => pageData.handleNameUpdate(event.currentTarget.value)}
        required
      />

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCreate}
        >
          Create
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>

    </div>
  )
}

export default LocationCreate;