import Player from "../types/Player";
import { PlayerOption } from "../types/PlayerOptions";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { deleteApiData, getApiData, patchApiData, postApiData } from "../utils/scorebenchApi";

class PlayerService
{
  private players: Player[] = [];

  getPlayers = async (leagueId: string, forceRefresh?: boolean): Promise<Player[]> =>
  {
    if (this.players.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/player`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as Player[]

      this.players = records;
    }

    return this.players;
  }

  getPlayer = async (leagueId: string, playerId: string): Promise<Player | null> =>
  {
    const targetPlayer = (await this.getPlayers(leagueId))
      .find(l => l.id === playerId);

    return targetPlayer ?? null;
  }

  createPlayer = async (newPlayer: Player) =>
  {
    const apiSuffix = `league/${newPlayer.leagueId}/player`

    await postApiData(apiSuffix, newPlayer);

    this.players = [];
  }

  updatePlayer = async (updatedPlayer: Player) =>
  {
    const apiSuffix = `league/${updatedPlayer.leagueId}/player/${updatedPlayer.id}`

    await patchApiData(apiSuffix, updatedPlayer);

    this.players = [];
  }

  deletePlayer = async (leagueId: string, playerId: string): Promise<void> =>
  {
    const apiSuffix = `league/${leagueId}/player/${playerId}`

    await deleteApiData(apiSuffix);

    this.players = [];
  }

  deletePlayers = async (leagueId: string, playerIds: string[]): Promise<void> =>
  {
    await Promise.all(playerIds.map(async (playerId: string) => await this.deletePlayer(leagueId, playerId)))
  }

  getPlayerOptions = async (leagueId: string, forceRefresh?: boolean): Promise<PlayerOption[]> =>
  {
    const players = await this.getPlayers(leagueId, forceRefresh);

    return players
      .filter(p => p.id)
      .map((player) =>
      {
        return {
          value: player.id,
          label: `${player.firstName} ${player.surname}`
        } as PlayerOption
      })
  }
}

export const playerService = new PlayerService();