import { Button, LoadingOverlay, Select, TextInput } from "@mantine/core";
import { usePoolCreatePageData } from "./usePoolCreatePage";
import { useParams } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import usePoolCreatePage from "./usePoolCreatePage";

const PoolCreate: React.FC = () =>
{
  const { leagueId, seasonId } = useParams();

  const pageData: usePoolCreatePageData = usePoolCreatePage({ leagueId, seasonId });

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">CREATE POOL</p>
      <TextInput
        placeholder="Team name"
        label="Team name"
        value={pageData.poolName}
        onChange={(event) => pageData.handleNameUpdate(event.currentTarget.value)}
        required
      />

      <Select
        placeholder="Division"
        label="Division"
        data={pageData.divisionOptions}
        value={pageData.divisionId}
        onChange={pageData.handleDivisionUpdate}
        required
        searchable
      />

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCreate}
        >
          Create
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>

    </div>
  )
}

export default PoolCreate;