import { useEffect, useState } from "react";
import { SeasonOption } from "../../types/SeasonOption";
import RequestStatus from "../../types/RequestStatus";
import { handleRowSelect } from "../../utils/handlers";
import { useNavigate } from "react-router-dom";
import Game from "../../types/Game";
import Competitor from "../../types/Competitor";
import { CompetitorReference } from "../../types/CompetitorReference";
import useLeague from "../../hooks/useLeague";
import useDivisions from "../../hooks/useDivisions";
import usePools from "../../hooks/usePools";
import useLocations from "../../hooks/useLocations";
import useGames from "../../hooks/useGames";
import useCompetitors from "../../hooks/useCompetitors";
import useSeasons from "../../hooks/useSeasons";

export interface UseGamesPageData
{
  games: Game[],
  competitors: Competitor[],
  currentSeason: string,
  selectedGames: string[],
  seasonOptions: SeasonOption[],
  requestStatus: RequestStatus,
  displayDeleteModal: boolean,
  getCompetitorReferenceText(competitorReference: CompetitorReference): string,
  getDivisionText(divisionId: string): string,
  getLocationText(locationId: string): string,
  getPoolText(poolId: string): string,
  handleSeasonSelect(seasonId: string | null): void,
  handleGameSelect(gameId: string): void,
  handleSelectAll(): void,
  handleClearSelection(): void,
  handleEditClick(seasonId: string, gameId: string): void,
  handleCreateClick(seasonId: string): void,
  handleImportClick(): void,
  deleteSelectedGames(): Promise<void>,
  showDeleteModal(): void,
  closeDeleteModal(): void,
  isSelected(gameId: string): boolean,
}

export interface UseGamesPageProps
{
  leagueId: string,
  seasonId: string
}

function useGamesPage(props: UseGamesPageProps): UseGamesPageData
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [currentSeason, setCurrentSeason] = useState<string>("");
  const [selectedGames, setSelectedGames] = useState<string[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);

  const leagueData = useLeague();
  const seasonData = useSeasons();
  const divisionData = useDivisions();
  const poolData = usePools();
  const locationData = useLocations();

  const gamesData = useGames();
  const competitorData = useCompetitors();

  const handleSeasonSelect = (seasonId: string | null) => setCurrentSeason(seasonId ?? "")
  const handleSelectAll = () => setSelectedGames(gamesData.games.map(g => g.id ?? ""));
  const handleClearSelection = () => setSelectedGames([]);
  const handleEditClick = (seasonId: string, gameId: string) => navigate(`/league/${props.leagueId}/season/${seasonId}/game/${gameId}/edit`);
  const handleCreateClick = (seasonId: string) => navigate(`/league/${props.leagueId}/season/${seasonId}/game/create`);
  const handleImportClick = () => navigate(`/league/${props.leagueId}/season/${currentSeason}/game/import`);
  const showDeleteModal = () => setDisplayDeleteModal(true);
  const closeDeleteModal = () => setDisplayDeleteModal(false);
  const isSelected = (gameId: string) => selectedGames.includes(gameId);

  const navigate = useNavigate();

  const deleteSelectedGames = async () =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      await gamesData.deleteGames(currentSeason, selectedGames);

      setDisplayDeleteModal(false);
      setSelectedGames([]);

      setRequestStatus(RequestStatus.SUCCESS);
    }
    catch
    {
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  // Update game data when season changes
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (currentSeason)
        {
          navigate(`/league/${props.leagueId}/season/${currentSeason}/games`);

          await competitorData.refreshCompetitors(currentSeason);
          await divisionData.refreshDivisions(currentSeason);
          await poolData.refreshPools(currentSeason);

          await gamesData.refreshGames(currentSeason);

          setSelectedGames([]);
        }

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, [currentSeason])

  // Initialisation on page load
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId || !props.seasonId)
        {
          const league = await leagueData.refreshLeague();
          const leagueId = league.id;
          const seasonId = league.defaultSeasonId;

          navigate(`/league/${leagueId}/season/${seasonId}/games`);
        }

        await seasonData.refreshSeasons();
        await locationData.refreshLocations();

        setCurrentSeason(props.seasonId);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, [props.leagueId]);

  return {
    games: gamesData.games,
    competitors: competitorData.competitors,
    currentSeason,
    selectedGames,
    requestStatus,
    displayDeleteModal,
    getCompetitorReferenceText: competitorData.getCompetitorReferenceText,
    getDivisionText: divisionData.getDivisionText,
    getLocationText: locationData.getLocationText,
    getPoolText: poolData.getPoolText,
    handleSeasonSelect,
    handleGameSelect: (gameId: string) => handleRowSelect(gameId, selectedGames, setSelectedGames),
    handleSelectAll,
    handleClearSelection,
    handleEditClick,
    handleCreateClick,
    handleImportClick,
    deleteSelectedGames,
    showDeleteModal,
    closeDeleteModal,
    isSelected,
    seasonOptions: seasonData.getSeasonOptions()
  }
}

export default useGamesPage;