import { useEffect, useRef, useState } from "react"
import { PoolOptions } from "../../types/IPoolOptions";
import { DivisionOptions } from "../../types/DivisionOptions";
import { divisionService } from "../../services/DivisionService";
import { poolService } from "../../services/PoolService";
import RequestStatus from "../../types/RequestStatus";
import { logoService } from "../../services/LogoService";
import { LogoOptions } from "../../types/LogoOptions";
import { useNavigate } from "react-router-dom";
import Competitor from "../../types/Competitor";
import { competitorService } from "../../services/CompetitorService";
import Player from "../../types/Player";
import { PlayerOption } from "../../types/PlayerOptions";
import { playerService } from "../../services/PlayerService";

export interface UseCompetitorCreatePageData
{
  competitorName: string,
  divisionId: string,
  poolId: string,
  logoId: string,
  playerIds: string[],
  poolOptions: PoolOptions[],
  divisionOptions: DivisionOptions[],
  logoOptions: LogoOptions[],
  playerOptions: PlayerOption[],
  requestStatus: RequestStatus,
  handlePoolUpdate(poolId: string): void,
  handleDivisionUpdate(divisionId: string): void,
  handleNameUpdate(name: string): void,
  handleLogoUpdate(logoId: string): void,
  handleAddPlayer(playerId: string): void,
  handleRemovePlayer(playerId: string): void,
  handleRemovePlayers(): void,
  handleCreate(): void,
  handleCancel(): void,
  getCompetitorPlayers(): Player[]
}

export interface UseCompetitorCreatePageProps
{
  leagueId?: string,
  seasonId?: string
}

const useCompetitorCreatePage = (props: UseCompetitorCreatePageProps): UseCompetitorCreatePageData =>
{
  const navigate = useNavigate();

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [competitorName, setCompetitorName] = useState("");
  const [divisionId, setDivisionId] = useState("");
  const [poolId, setPoolId] = useState("");
  const [logoId, setLogoId] = useState("");
  const [playerIds, setPlayerIds] = useState<string[]>([]);

  const [poolOptions, setPoolOptions] = useState<PoolOptions[]>([]);
  const [divisionOptions, setDivisionOptions] = useState<DivisionOptions[]>([]);
  const [logoOptions, setLogoOptions] = useState<LogoOptions[]>([]);
  const [playerOptions, setPlayerOptions] = useState<PlayerOption[]>([]);

  const players = useRef<Player[]>();

  const handlePoolUpdate = (poolId: string) => setPoolId(poolId);
  const handleLogoUpdate = (logoId: string) => setLogoId(logoId);
  const handleNameUpdate = (name: string) => setCompetitorName(name);
  const handleRemovePlayers = () => setPlayerIds([]);
  const handleCancel = () => navigate("/Teams");

  const getCompetitorPlayers = () => players.current?.filter(p => playerIds.includes(p.id ?? "")) ?? [];

  const handleCreate = () =>
  {
    const createCompetitor = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId || !props.seasonId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const newCompetitor: Competitor = {
          id: "",
          name: competitorName,
          divisionId,
          poolId,
          leagueId: props.leagueId,
          seasonId: props.seasonId,
          logoId,
          playerIds
        }

        await competitorService.createCompetitor(newCompetitor);

        navigate("/Teams");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    createCompetitor();
  }

  const handleAddPlayer = (playerId: string) =>
  {
    const updatedPlayerIds = [...playerIds, playerId];

    setPlayerIds(
      Array.from(new Set(updatedPlayerIds)
      ));
  }

  const handleRemovePlayer = (playerId: string) =>
  {
    const updatedPlayerIds = playerIds.filter(p => p !== playerId);

    setPlayerIds(updatedPlayerIds);
  }

  const handleDivisionUpdate = (divisionId: string) =>
  {
    setDivisionId(divisionId);
    setPoolId("");
  }

  const fetchDivisions = async () =>
  {
    if (!props.leagueId || !props.seasonId)
    {
      return
    }

    const divisionOptionsData = await divisionService.getDivisionOptions(props.leagueId, props.seasonId, true);

    setDivisionOptions(divisionOptionsData);
  }

  const fetchPools = async () =>
  {
    if (!props.leagueId || !props.seasonId)
    {
      return
    }

    const poolOptionsData = await poolService.getPoolOptions(props.leagueId, props.seasonId, divisionId, true)

    setPoolOptions(poolOptionsData)
  }

  const fetchLogos = async () =>
  {
    if (!props.leagueId)
    {
      return
    }

    const logoOptionsData = await logoService.getLogoOptions(props.leagueId, true);

    setLogoOptions(logoOptionsData);
  }

  const fetchPlayers = async () =>
  {
    if (!props.leagueId)
    {
      return
    }

    const playerOptionsData = await playerService.getPlayerOptions(props.leagueId, true);

    players.current = await playerService.getPlayers(props.leagueId);

    setPlayerOptions(playerOptionsData);
  }

  useEffect(() => 
  {
    fetchPools()
  }, [divisionId])

  // Initialise data on load
  useEffect(() =>
  {
    const initData = async () =>
    {
      try
      {
        if (!props.leagueId || !props.seasonId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setRequestStatus(RequestStatus.LOADING);

        await fetchDivisions();
        await fetchPools();
        await fetchLogos();
        await fetchPlayers();

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    initData();
  }, [])

  return {
    competitorName,
    divisionId,
    poolId,
    logoId,
    playerIds,
    divisionOptions,
    poolOptions,
    logoOptions,
    playerOptions,
    requestStatus,
    handlePoolUpdate,
    handleDivisionUpdate,
    handleNameUpdate,
    handleLogoUpdate,
    handleAddPlayer,
    handleRemovePlayer,
    handleRemovePlayers,
    handleCreate,
    handleCancel,
    getCompetitorPlayers
  }
}

export default useCompetitorCreatePage;