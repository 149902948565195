import { Button, LoadingOverlay, TextInput } from "@mantine/core";
import { useParams } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import useLogoEditPage, { UseLogoEditPageData } from "./useLogoEditPage";

const LogoEdit: React.FC = () =>
{
  const { leagueId, logoId } = useParams();

  const pageData: UseLogoEditPageData = useLogoEditPage({ leagueId, logoId });

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">EDIT LOGO</p>
      <TextInput
        placeholder="Logo name"
        label="Logo name"
        value={pageData.logoName}
        onChange={(event) => pageData.handleNameUpdate(event.currentTarget.value)}
        required
      />

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleUpdate}
        >
          Create
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>

    </div>
  )
}

export default LogoEdit;