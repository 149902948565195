import { useRef, useState } from "react"
import { GameEvent, GameEventType, GameGoalForEvent, GamePenaltyEvent, GameShotForEvent } from "../../types/GameEvent";

export interface UseGameEventsData
{
  createShotEvent(competitorId: string, goalieId: string): void,
  createGoalEvent(competitorId: string, playerId?: string, assistOneId?: string, assistTwoId?: string, goalieId?: string): void
  createPenaltyEvent(competitorId: string, penaltyId: string, playerId?: string): void
  getGameEvents(eventTypes: GameEventType[]): GameEvent[],
  deleteGameEvent(eventId: number): void,
  loadEvents(events: GameEvent[]): void,
  gameEvents: GameEvent[]
}

const useGameEvents = (): UseGameEventsData =>
{
  const [gameEvents, setGameEvents] = useState<GameEvent[]>([]);

  const eventId = useRef(0);

  const generateEventId = (): number =>
  {
    eventId.current++;

    return eventId.current;
  }

  const deleteGameEvent = (eventId: number) => setGameEvents(gameEvents.filter(e => e.eventId !== eventId));

  const getGameEvents = (eventTypes: GameEventType[]): GameEvent[] =>
  {
    if (eventTypes.length > 0)
    {
      return gameEvents.filter(e => eventTypes.includes(e.type));
    }

    return gameEvents;
  }

  const createShotEvent = (competitorId: string, goalieId: string) =>
  {
    const shotEvent: GameShotForEvent = {
      type: GameEventType.SHOT,
      eventId: generateEventId(),
      competitorId,
      goalieId
    }

    setGameEvents([...gameEvents, shotEvent]);
  }

  const createGoalEvent = (competitorId: string, playerId?: string, assistOneId?: string, assistTwoId?: string, goalieId?: string): void =>
  {
    const goalEvent: GameGoalForEvent = {
      type: GameEventType.GOAL,
      eventId: generateEventId(),
      competitorId,
      playerId,
      goalieId,
      assistIds: [assistOneId ?? "", assistTwoId ?? ""]
    }

    setGameEvents([...gameEvents, goalEvent])
  }

  const createPenaltyEvent = (competitorId: string, penaltyId: string, playerId?: string): void =>
  {
    const penaltyEvent: GamePenaltyEvent = {
      type: GameEventType.PENALTY,
      eventId: generateEventId(),
      competitorId,
      playerId,
      penaltyId
    }

    setGameEvents([...gameEvents, penaltyEvent])
  }

  const loadEvents = (events: GameEvent[]): void =>
  {
    setGameEvents(events);

    const currentEventId = Math.max(...events.map(e => e.eventId));

    if (!(Number.isInteger(currentEventId) && currentEventId >= 0))
    {
      eventId.current = 0;

      return;
    }

    eventId.current = currentEventId;
  }

  return {
    createShotEvent,
    createGoalEvent,
    createPenaltyEvent,
    getGameEvents,
    deleteGameEvent,
    loadEvents,
    gameEvents
  }
}

export default useGameEvents;