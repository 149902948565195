import { useParams } from "react-router-dom";
import useGameCreate, { UseGameCreateData, UseGameCreateProps } from "./useGameCreate";
import { Grid, LoadingOverlay, TextInput, Select, Button } from "@mantine/core";
import RequestStatus from "../../types/RequestStatus";
import { DatePicker, TimeInput } from "@mantine/dates";

const GameCreate: React.FC = () =>
{
  const { leagueId, seasonId } = useParams();

  const gameCreateProps: UseGameCreateProps = {
    leagueId: leagueId ?? "",
    seasonId: seasonId ?? ""
  }

  const pageData: UseGameCreateData = useGameCreate(gameCreateProps)

  return (
    <div className="dataWindow">
      <Grid>
        <Grid.Col span={4}>
          <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} radius="sm" />
          <p className="title">CREATE GAME</p>
          <TextInput
            placeholder="Game ID"
            label="Game ID"
            value={pageData.gameId}
            onChange={(event) => pageData.handleSetGameId(event.currentTarget.value)}
            required
          />

          <Select
            placeholder="Division"
            label="Division"
            data={pageData.divisionOptions}
            value={pageData.divisionId}
            onChange={pageData.handleSetDivisionId}
            required
            searchable
          />

          <Select
            placeholder="Home team"
            label="Home team"
            data={pageData.competitorOptions}
            value={pageData.competitorOneId}
            onChange={pageData.handleSetCompetitorOneId}
            required
            searchable
          />

          <Select
            placeholder="Away team"
            label="Away team"
            data={pageData.competitorOptions}
            value={pageData.competitorTwoId}
            onChange={pageData.handleSetCompetitorTwoId}
            required
            searchable
          />

          <Select
            placeholder="Location"
            label="Location"
            data={pageData.locationOptions}
            value={pageData.locationId}
            onChange={pageData.handleSetLocationId}
            required
            searchable
          />

          <DatePicker
            placeholder="Game date"
            label="Game date"
            value={pageData.gameTime}
            onChange={pageData.handleSetGameDate}
            required
          />

          <TimeInput
            placeholder="Game time"
            label="Game time"
            value={pageData.gameTime}
            onChange={pageData.handleSetGameTime}
            required
          />

        </Grid.Col>
      </Grid>

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleGameCreate}
        >
          Create
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default GameCreate;