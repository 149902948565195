import { useState, useRef, useEffect } from "react";
import Division from "../../types/Division";
import League from "../../types/League";
import RequestStatus from "../../types/RequestStatus";
import Season from "../../types/Season";
import { SeasonOption } from "../../types/SeasonOption";
import { divisionService } from "../../services/DivisionService";
import { leagueService } from "../../services/LeagueService";
import { seasonService } from "../../services/SeasonService";
import { handleRowSelect } from "../../utils/handlers";
import { useNavigate } from "react-router-dom";

export interface UseDivisionsPageData
{
  divisions: Division[],
  currentSeason: string,
  selectedDivisions: string[],
  seasonOptions: SeasonOption[],
  requestStatus: RequestStatus,
  displayDeleteModal: boolean,
  getSeasonName(seasonId: string): string,
  handleSeasonSelect(seasonId: string | null): void,
  handleDivisionSelect(divisionId: string): void,
  handleSelectAll(): void,
  handleClearSelection(): void,
  handleCreateClick(): void,
  handleEditClick(division: Division): void,
  deleteSelectedDivisions(): Promise<void>,
  showDeleteModal(): void,
  closeDeleteModal(): void,
  isSelected(divisionId: string): boolean
}

const useDivisionsPage = (): UseDivisionsPageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [currentSeason, setCurrentSeason] = useState<string>("");
  const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
  const [seasonOptions, setSeasonOptions] = useState<SeasonOption[]>([]);

  const league = useRef<League | null>(null);
  const seasons = useRef<Season[]>([]);

  const navigate = useNavigate();

  const handleSeasonSelect = (seasonId: string | null) => setCurrentSeason(seasonId ?? "")
  const handleSelectAll = () => setSelectedDivisions(divisions.map(d => d.id));
  const handleClearSelection = () => setSelectedDivisions([]);
  const handleCreateClick = () => navigate(`/league/${league.current?.id}/season/${currentSeason}/division/create`);
  const handleEditClick = (division: Division) => navigate(`/league/${league.current?.id}/season/${currentSeason}/division/${division.id}/edit`);
  const showDeleteModal = () => setDisplayDeleteModal(true);
  const closeDeleteModal = () => setDisplayDeleteModal(false);
  const isSelected = (divisionId: string) => selectedDivisions.includes(divisionId);

  const getSeasonName = (seasonId: string) => seasons.current.find(s => s.id === seasonId)?.name ?? "Unknown";

  const fetchDivisions = async (leagueId: string, seasonId: string, forceRefresh?: boolean) =>
  {
    const divisionData = await divisionService.getDivisions(leagueId, seasonId, forceRefresh);

    setDivisions(divisionData);
  }

  const fetchSeasons = async (leagueId: string) =>
  {
    const seasonData = await seasonService.getSeasons(leagueId, true);

    seasons.current = seasonData;
  }

  const fetchSeasonOptions = async (leagueId: string) =>
  {
    const seasonOptionData = await seasonService.getSeasonOptions(leagueId);

    setSeasonOptions(seasonOptionData);
  }

  const deleteSelectedDivisions = async () =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      if (league.current?.id && currentSeason && selectedDivisions.length > 0)
      {
        await divisionService.deleteDivisions(league.current.id, currentSeason, selectedDivisions);

        setDisplayDeleteModal(false);

        await fetchDivisions(league.current.id, currentSeason, true);

        setSelectedDivisions([]);
      }

      setRequestStatus(RequestStatus.SUCCESS);
    }
    catch
    {
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  // Update division data when season changes
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (currentSeason && league.current?.id)
        {
          await fetchDivisions(league.current.id, currentSeason, true);

          setSelectedDivisions([]);
        }

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, [currentSeason])

  // Initialisation on page load
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        const leagueData = await leagueService.getLeague();

        league.current = leagueData;

        if (leagueData)
        {
          await fetchSeasons(league.current.id);

          setCurrentSeason(leagueData.defaultSeasonId ?? "");

          await fetchSeasonOptions(leagueData.id);
        }
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, []);

  return {
    divisions,
    currentSeason,
    selectedDivisions,
    requestStatus,
    displayDeleteModal,
    getSeasonName,
    handleSeasonSelect,
    handleDivisionSelect: (divisionId: string) => handleRowSelect(divisionId, selectedDivisions, setSelectedDivisions),
    handleSelectAll,
    handleClearSelection,
    handleCreateClick,
    handleEditClick,
    deleteSelectedDivisions,
    showDeleteModal,
    closeDeleteModal,
    isSelected,
    seasonOptions
  }
}

export default useDivisionsPage;