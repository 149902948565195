import Logo from "../types/Logo";
import { LogoOptions } from "../types/LogoOptions";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { deleteApiData, getApiData, patchApiData, postApiData } from "../utils/scorebenchApi";

class LogoService
{
  private logos: Logo[] = [];

  getLogos = async (leagueId: string, forceRefresh?: boolean): Promise<Logo[]> =>
  {
    if (this.logos.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/logo`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as Logo[]

      this.logos = records;
    }

    return this.logos;
  }

  getLogo = async (leagueId: string, logoId: string): Promise<Logo | null> =>
  {
    const targetLogo = (await this.getLogos(leagueId))
      .find(l => l.id === logoId);

    return targetLogo ?? null;
  }

  createLogo = async (newLogo: Logo) =>
  {
    const apiSuffix = `league/${newLogo.leagueId}/logo`

    await postApiData(apiSuffix, newLogo);

    this.logos = [];
  }

  updateLogo = async (updatedLogo: Logo) =>
  {
    const apiSuffix = `league/${updatedLogo.leagueId}/logo`

    await patchApiData(apiSuffix, updatedLogo);

    this.logos = [];
  }

  deleteLogo = async (leagueId: string, logoId: string): Promise<void> =>
  {
    const apiSuffix = `league/${leagueId}/logo/${logoId}`

    await deleteApiData(apiSuffix);

    this.logos = [];
  }

  deleteLogos = async (leagueId: string, logoIds: string[]): Promise<void> =>
  {
    await Promise.all(logoIds.map(async (logoId: string) => await this.deleteLogo(leagueId, logoId)))
  }

  getLogoOptions = async (leagueId: string, forceRefresh?: boolean): Promise<LogoOptions[]> =>
  {
    return (await this.getLogos(leagueId, forceRefresh))
      .map((logo) =>
      {
        return {
          value: logo.id,
          label: logo.name
        }
      })
  }
}

export const logoService = new LogoService();