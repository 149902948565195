import { useState } from "react"
import RequestStatus from "../../types/RequestStatus";
import { useNavigate } from "react-router-dom";
import { locationService } from "../../services/LocationService";
import Location from "../../types/Location";

export interface UseLocationCreatePageData
{
  locationName: string,
  requestStatus: RequestStatus,
  handleNameUpdate(name: string): void,
  handleCreate(): void,
  handleCancel(): void
}

export interface UseLocationCreatePageProps
{
  leagueId?: string
}

const useLocationCreatePage = (props: UseLocationCreatePageProps): UseLocationCreatePageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [locationName, setLocationName] = useState("");

  const handleNameUpdate = (name: string) => setLocationName(name);

  const navigate = useNavigate();

  const handleCancel = () => navigate("/Locations");

  const handleCreate = () =>
  {
    const createLocation = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const newLocation: Location = {
          id: "",
          name: locationName,
          leagueId: props.leagueId
        }

        await locationService.createLocation(newLocation);

        navigate("/Locations");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    createLocation();
  }

  return {
    locationName,
    requestStatus,
    handleNameUpdate,
    handleCreate,
    handleCancel
  }
}

export default useLocationCreatePage;