import { useEffect } from "react"
import useDivisions from "../../hooks/useDivisions"
import Competitor from "../../types/Competitor"

export interface UseCompetitorPanelData
{
  competitorText: string,
  divisionName: string
}

export interface UseCompetitorPanelProps
{
  competitor: Competitor
}

const useCompetitorPanel = (props: UseCompetitorPanelProps): UseCompetitorPanelData =>
{
  const divisionData = useDivisions();

  useEffect(() =>
  {
    const loadData = async () =>
    {
      await divisionData.refreshDivisions(props.competitor.seasonId);
    }

    loadData();
  }, [props.competitor])

  return {
    competitorText: props.competitor.name,
    divisionName: divisionData.getDivisionText(props.competitor.divisionId)
  }
}

export default useCompetitorPanel;