import { useEffect, useRef, useState } from "react";
import RequestStatus from "../../types/RequestStatus";
import { gameService } from "../../services/GameService";
import { competitorService } from "../../services/CompetitorService";
import { StateOptions } from "../../types/StateOptions";
import { playerService } from "../../services/PlayerService";
import { PlayerOption } from "../../types/PlayerOptions";
import Player from "../../types/Player";
import useGameEvents from "./useEvents";
import { GameEvent, GameEventType, GameShotForEvent } from "../../types/GameEvent";
import Game, { GameState } from "../../types/Game";
import { seasonService } from "../../services/SeasonService";
import { CompetitorIdReference } from "../../types/CompetitorReference";
import useLocations from "../../hooks/useLocations";
import usePools from "../../hooks/usePools";
import useDivisions from "../../hooks/useDivisions";
import useCompetitors from "../../hooks/useCompetitors";
import Competitor from "../../types/Competitor";

export interface UseGameEditProps
{
  leagueId: string,
  seasonId: string,
  gameId: string
}

export interface UseGameEditData
{
  leagueId: string,
  seasonId: string,
  gameId: string,
  gameState: GameState,
  gameTime: Date,
  seasonName: string,
  locationName: string,
  divisionName: string,
  poolName: string,
  playerOptions: PlayerOption[],
  stateOptions: StateOptions[],
  competitorOne: Competitor,
  competitorTwo: Competitor,
  competitorOneGoalsFor: number,
  competitorTwoGoalsFor: number,
  competitorOneShotsFor: number,
  competitorTwoShotsFor: number,
  competitorOneGoalieId: string,
  competitorTwoGoalieId: string
  handleAddCompetitorOnePlayer(playerId: string): void,
  handleAddCompetitorOneGoalie(goalieId: string): void,
  handleAddCompetitorOneDefaultPlayers(): void,
  handleRemoveCompetitorOneGoalie(goalieId: string): void,
  handleRemoveCompetitorOnePlayer(playerId: string): void,
  handleRemoveCompetitorOnePlayers(): void,
  handleAddCompetitorTwoPlayer(playerId: string): void,
  handleAddCompetitorTwoGoalie(goalieId: string): void,
  handleAddCompetitorTwoDefaultPlayers(): void,
  handleRemoveCompetitorTwoPlayer(playerId: string): void,
  handleRemoveCompetitorTwoGoalie(goalieId: string): void,
  handleRemoveCompetitorTwoPlayers(): void,
  handleCompetitorOneShotFor(): void,
  handleCompetitorTwoShotFor(): void,
  handleCompetitorOneGoalFor(playerId?: string, assistOneId?: string, assistTwoId?: string): void,
  handleCompetitorTwoGoalFor(playerId?: string, assistOneId?: string, assistTwoId?: string): void,
  handleCompetitorOnePenalty(penaltyId: string, playerId?: string): void,
  handleCompetitorTwoPenalty(penaltyId: string, playerId?: string): void,
  handleGameUpdate(): void,
  setGameState(state: GameState): void,
  setCompetitorOneGoalieId(playerId: string): void,
  setCompetitorTwoGoalieId(playerId: string): void,
  error: string | null
  requestStatus: RequestStatus,
  getCompetitorOnePlayers(): Player[],
  getCompetitorTwoPlayers(): Player[],
  getCompetitorOneGoalies(): Player[],
  getCompetitorTwoGoalies(): Player[],
  getGameEvents(eventTypes: GameEventType[]): GameEvent[],
  deleteGameEvent(eventId: number): void
}

function useGameEdit(props: UseGameEditProps): UseGameEditData
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [error, setError] = useState<string | null>(null);

  const [playerOptions, setPlayerOptions] = useState<PlayerOption[]>([]);
  const [competitorOnePlayerIds, setCompetitorOnePlayerIds] = useState<string[]>([]);
  const [competitorOneGoalieIds, setCompetitorOneGoalieIds] = useState<string[]>([]);
  const [competitorTwoPlayerIds, setCompetitorTwoPlayerIds] = useState<string[]>([]);
  const [competitorTwoGoalieIds, setCompetitorTwoGoalieIds] = useState<string[]>([]);
  const [competitorOneGoalieId, setCompetitorOneGoalieId] = useState<string>("");
  const [competitorTwoGoalieId, setCompetitorTwoGoalieId] = useState<string>("");
  const [gameState, setGameState] = useState<GameState>(GameState.SCHEDULED);

  const locationData = useLocations();
  const poolsData = usePools();
  const divisionData = useDivisions();
  const competitorData = useCompetitors();

  const game = useRef<Game>();
  const players = useRef<Player[]>();
  const competitorOne = useRef<CompetitorIdReference>();
  const competitorTwo = useRef<CompetitorIdReference>();

  const seasonName = useRef<string>("");
  const gameTime = useRef<Date>();

  const gameEvents = useGameEvents();

  const handleRemoveCompetitorOnePlayers = () => 
  {
    setCompetitorOneGoalieIds([]);
    setCompetitorOneGoalieId("");
    setCompetitorOnePlayerIds([]);
  }

  const handleRemoveCompetitorTwoPlayers = () => 
  {
    setCompetitorTwoGoalieIds([]);
    setCompetitorTwoGoalieId("");
    setCompetitorTwoPlayerIds([]);
  }

  const addCompetitorShot = (goalieId: string, competitor?: CompetitorIdReference) =>
  {
    if (!competitor) return;

    gameEvents.createShotEvent(
      competitor.id, goalieId);
  }

  const addCompetitorGoal = (goalieId: string, competitor?: CompetitorIdReference, playerId?: string, assistOneId?: string, assistTwoId?: string) =>
  {
    if (!competitor) return;

    gameEvents.createGoalEvent(
      competitor.id, playerId, assistOneId, assistTwoId, goalieId);
  }

  const addCompetitorPenalty = (penaltyId: string, competitor?: CompetitorIdReference, playerId?: string) =>
  {
    if (!competitor) return;

    gameEvents.createPenaltyEvent(competitor.id, penaltyId, playerId);
  }

  const getCompetitorShotsFor = (competitor?: CompetitorIdReference) =>
  {
    return gameEvents.getGameEvents([GameEventType.SHOT, GameEventType.GOAL])
      .filter(e => (e as GameShotForEvent).competitorId === competitor?.id)
      .length;
  }

  const getCompetitorGoalsFor = (competitor?: CompetitorIdReference) =>
  {
    return gameEvents.getGameEvents([GameEventType.GOAL])
      .filter(e => (e as GameShotForEvent).competitorId === competitor?.id)
      .length;
  }

  const getCompetitorPlayers = (playerIds: string[]): Player[] =>
  {
    if (!players.current || !playerIds) return [];

    return players.current.filter(p => playerIds.includes(p.id ?? "")) ?? []
  }

  const fetchPlayers = async () =>
  {
    if (!props.leagueId)
    {
      return
    }

    const playerOptionsData = await playerService.getPlayerOptions(props.leagueId, true);

    players.current = await playerService.getPlayers(props.leagueId);

    setPlayerOptions(playerOptionsData);
  }

  const loadDefaultPlayers = (setCompetitorPlayersIds: React.Dispatch<React.SetStateAction<string[]>>, setCompetitorGoalieIds: React.Dispatch<React.SetStateAction<string[]>>, competitor?: CompetitorIdReference) =>
  {
    const loadPlayers = async () =>
    {
      if (!props.leagueId || !props.seasonId || !competitor)
      {
        return;
      }

      setRequestStatus(RequestStatus.LOADING);

      const defaultPlayers = await competitorService.getCompetitorPlayers(
        props.leagueId, props.seasonId, competitor.id);

      setCompetitorPlayersIds(
        Array.from(new Set(defaultPlayers)
        ));

      const defaultGoalies = await competitorService.getCompetitorGoalies(
        props.leagueId, props.seasonId, competitor.id);

      setCompetitorGoalieIds(
        Array.from(new Set(defaultGoalies)
        ));

      setRequestStatus(RequestStatus.SUCCESS);
    }

    loadPlayers();
  }

  const addCompetitorPlayer = (playerId: string, setCompetitorPlayerIds: React.Dispatch<React.SetStateAction<string[]>>) =>
  {
    setCompetitorPlayerIds(currentPlayers => Array.from(new Set([...currentPlayers, playerId])));
  }

  const removeCompetitorPlayer = (playerId: string, setCompetitorPlayerIds: React.Dispatch<React.SetStateAction<string[]>>) =>
  {
    setCompetitorPlayerIds(currentPlayers => currentPlayers.filter(p => p !== playerId));
  }

  const loadGame = async () =>
  {
    const targetGame = await gameService.getGame(props.leagueId, props.seasonId, props.gameId);

    if (!targetGame)
    {
      throw new Error(`Game ID <${props.gameId}> not found`);
    }

    const targetSeason = await seasonService.getSeason(props.leagueId, props.seasonId);

    setCompetitorOnePlayerIds(targetGame.competitorOnePlayers);
    setCompetitorTwoPlayerIds(targetGame.competitorTwoPlayers);

    setCompetitorOneGoalieIds(targetGame.competitorOneGoalies);
    setCompetitorTwoGoalieIds(targetGame.competitorTwoGoalies);

    game.current = targetGame;

    seasonName.current = targetSeason?.name ?? "";
    competitorOne.current = (targetGame.competitorOne as CompetitorIdReference);
    competitorTwo.current = (targetGame.competitorTwo as CompetitorIdReference);
    gameTime.current = new Date(targetGame.time);

    setGameState(targetGame.gameState);

    await gameEvents.loadEvents(targetGame.events ?? []);
  }

  const handleGameUpdate = () =>
  {
    const updateGame = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!game.current)
        {
          setRequestStatus(RequestStatus.FAILURE);

          throw new Error("Game data not populated");
        }

        const updatedGame = game.current;

        updatedGame.competitorOneScore = getCompetitorGoalsFor(competitorOne.current);
        updatedGame.competitorTwoScore = getCompetitorGoalsFor(competitorTwo.current);
        updatedGame.competitorOnePlayers = competitorOnePlayerIds;
        updatedGame.competitorTwoPlayers = competitorTwoPlayerIds;
        updatedGame.competitorOneGoalies = competitorOneGoalieIds;
        updatedGame.competitorTwoGoalies = competitorTwoGoalieIds;
        updatedGame.events = gameEvents.gameEvents;
        updatedGame.gameState = gameState;

        await gameService.updateGame(updatedGame);

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setError("Failed to update game");
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    updateGame();
  }

  // Initialise on page load
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId || !props.seasonId || !props.gameId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return;
        }

        await competitorData.refreshCompetitors(props.seasonId);
        await divisionData.refreshDivisions(props.seasonId);
        await locationData.refreshLocations();
        await poolsData.refreshPools(props.seasonId);

        await loadGame();
        await fetchPlayers();

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setError("Failed to load game details");
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, []);

  return {
    leagueId: props.leagueId ?? "",
    seasonId: props.seasonId ?? "",
    gameId: props.gameId ?? "",
    seasonName: seasonName.current,
    gameState,
    gameTime: gameTime.current ?? new Date(),
    locationName: locationData.getLocationText(game.current?.locationId ?? ""),
    divisionName: divisionData.getDivisionText(game.current?.divisionId ?? ""),
    poolName: poolsData.getPoolText(game.current?.poolId ?? ""),
    stateOptions: gameService.getStateOptions(),
    playerOptions,
    competitorOne: competitorData.getCompetitor(competitorOne.current?.id),
    competitorTwo: competitorData.getCompetitor(competitorTwo.current?.id),
    competitorOneGoalsFor: getCompetitorGoalsFor(competitorOne.current),
    competitorTwoGoalsFor: getCompetitorGoalsFor(competitorTwo.current),
    competitorOneShotsFor: getCompetitorShotsFor(competitorOne.current),
    competitorTwoShotsFor: getCompetitorShotsFor(competitorTwo.current),
    competitorOneGoalieId,
    competitorTwoGoalieId,
    handleAddCompetitorOnePlayer: (playerId: string) => addCompetitorPlayer(playerId, setCompetitorOnePlayerIds),
    handleAddCompetitorOneGoalie: (goalieId: string) => addCompetitorPlayer(goalieId, setCompetitorOneGoalieIds),
    handleAddCompetitorTwoPlayer: (playerId: string) => addCompetitorPlayer(playerId, setCompetitorTwoPlayerIds),
    handleAddCompetitorTwoGoalie: (goalieId: string) => addCompetitorPlayer(goalieId, setCompetitorTwoGoalieIds),
    handleAddCompetitorOneDefaultPlayers: () => loadDefaultPlayers(setCompetitorOnePlayerIds, setCompetitorOneGoalieIds, competitorOne.current),
    handleAddCompetitorTwoDefaultPlayers: () => loadDefaultPlayers(setCompetitorTwoPlayerIds, setCompetitorTwoGoalieIds, competitorTwo.current),
    handleRemoveCompetitorOneGoalie: (goalieId: string) => removeCompetitorPlayer(goalieId, setCompetitorOneGoalieIds),
    handleRemoveCompetitorOnePlayer: (playerId: string) => removeCompetitorPlayer(playerId, setCompetitorOnePlayerIds),
    handleRemoveCompetitorTwoPlayer: (playerId: string) => removeCompetitorPlayer(playerId, setCompetitorTwoPlayerIds),
    handleRemoveCompetitorTwoGoalie: (goalieId: string) => removeCompetitorPlayer(goalieId, setCompetitorTwoGoalieIds),
    handleRemoveCompetitorOnePlayers,
    handleRemoveCompetitorTwoPlayers,
    handleCompetitorOneShotFor: () => addCompetitorShot(competitorTwoGoalieId, competitorOne.current),
    handleCompetitorTwoShotFor: () => addCompetitorShot(competitorOneGoalieId, competitorTwo.current),
    handleCompetitorOneGoalFor: (playerId?: string, assistOneId?: string, assistTwoId?: string) => addCompetitorGoal(competitorTwoGoalieId, competitorOne.current, playerId, assistOneId, assistTwoId),
    handleCompetitorTwoGoalFor: (playerId?: string, assistOneId?: string, assistTwoId?: string) => addCompetitorGoal(competitorOneGoalieId, competitorTwo.current, playerId, assistOneId, assistTwoId),
    handleCompetitorOnePenalty: (penaltyId: string, playerId?: string) => addCompetitorPenalty(penaltyId, competitorOne.current, playerId),
    handleCompetitorTwoPenalty: (penaltyId: string, playerId?: string) => addCompetitorPenalty(penaltyId, competitorTwo.current, playerId),
    handleGameUpdate,
    setGameState,
    setCompetitorOneGoalieId,
    setCompetitorTwoGoalieId,
    error,
    requestStatus,
    getCompetitorOnePlayers: () => getCompetitorPlayers(competitorOnePlayerIds),
    getCompetitorTwoPlayers: () => getCompetitorPlayers(competitorTwoPlayerIds),
    getCompetitorOneGoalies: () => getCompetitorPlayers(competitorOneGoalieIds),
    getCompetitorTwoGoalies: () => getCompetitorPlayers(competitorTwoGoalieIds),
    getGameEvents: gameEvents.getGameEvents,
    deleteGameEvent: gameEvents.deleteGameEvent
  }
}

export default useGameEdit;