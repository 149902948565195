import { Button, Checkbox, LoadingOverlay, Select } from "@mantine/core";
import { Edit } from "@mui/icons-material";
import Competitor from "../../types/Competitor";
import { LOGOSIZE } from "../../types/LogoSize";
import RequestStatus from "../../types/RequestStatus";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import { Table, TableBody, TableDataCell, TableDataRow, TableHead, TableHeadCell, TableHeadRow } from "../TableComponents/TableComponents";
import useCompetitorPage, { UseCompetitorsPageData } from "./useCompetitorsPage";
import CompetitorLogo from "../CompetitorLogo/CompetitorLogo";

const Competitors = () =>
{
  const pageData: UseCompetitorsPageData = useCompetitorPage();

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />

      <p className="title">TEAMS</p>
      <div className="searchToolbar">
        <Select
          data={pageData.seasonOptions}
          value={pageData.currentSeason}
          searchable
          className="mb-4"
          onChange={pageData.handleSeasonSelect}
        />

        <Button
          variant="default"
          className="ml-4 mb-4"
          onClick={pageData.handleCreateClick}
        >
          Create
        </Button>

        <Button
          variant="default"
          className="ml-4 mb-4"
          onClick={pageData.handleSelectAll}
        >
          Select all
        </Button>

        <Button
          variant="default"
          disabled={pageData.selectedCompetitors.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.handleClearSelection}
        >
          Clear selection
        </Button>
        <Button
          variant="default"
          disabled={pageData.selectedCompetitors.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.showDeleteModal}
        >
          Delete selected
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell />
            <TableHeadCell />
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Division</TableHeadCell>
            <TableHeadCell>Pool</TableHeadCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {pageData.competitors.map((team: Competitor) => (
            <TableDataRow key={team.id}>
              <TableDataCell>
                <Checkbox
                  checked={pageData.selectedCompetitors.includes(team.id)}
                  onChange={() => pageData.handleCompetitorSelect(team.id)}
                />
              </TableDataCell>
              <TableDataCell>
                <CompetitorLogo competitor={team} size={LOGOSIZE.SMALL} center />
              </TableDataCell>
              <TableDataCell>{team.name}</TableDataCell>
              <TableDataCell>{pageData.getDivisonName(team.divisionId)}</TableDataCell>
              <TableDataCell>{pageData.getPoolName(team.poolId)}</TableDataCell>
              <TableDataCell>
                <Button
                  variant="light"
                  onClick={() => pageData.handleEditClick(team)}
                >
                  <Edit />
                </Button>
              </TableDataCell>
            </TableDataRow>
          ))}
        </TableBody>
      </Table>

      {pageData.displayDeleteModal &&
        <DeleteConfirmation
          opened={pageData.displayDeleteModal}
          handleDelete={pageData.deleteSelectedCompetitors}
          handleClose={pageData.closeDeleteModal}
        />}
    </div>
  )
}

export default Competitors;
