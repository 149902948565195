import League from "../types/League";
import { leagueService } from "../services/LeagueService";
import { useState } from "react";

export interface UseLeagueData
{
  league: League | null,
  refreshLeague(): Promise<League>
}

const useLeague = (): UseLeagueData =>
{
  const [league, setLeague] = useState<League | null>(null);

  const refreshLeague = async (forceRefresh?: boolean): Promise<League> => 
  {
    if (!league || forceRefresh)
    {
      const leagueData: League = await leagueService.getLeague();
      setLeague(leagueData);

      return leagueData
    }

    return league;
  }

  return {
    league,
    refreshLeague
  }
}

export default useLeague;