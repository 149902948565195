import { Button, LoadingOverlay, Select, TextInput } from "@mantine/core";
import { useParams } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import usePoolEditPage, { UsePoolEditPageData } from "./usePoolEditPage";

const PoolEdit: React.FC = () =>
{
  const { leagueId, seasonId, poolId } = useParams();

  const pageData: UsePoolEditPageData = usePoolEditPage({ leagueId, seasonId, poolId });

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">EDIT POOL</p>
      <TextInput
        placeholder="Team name"
        label="Team name"
        value={pageData.poolName}
        onChange={(event) => pageData.handleNameUpdate(event.currentTarget.value)}
        required
      />

      <Select
        placeholder="Division"
        label="Division"
        data={pageData.divisionOptions}
        value={pageData.divisionId}
        onChange={pageData.handleDivisionUpdate}
        required
        searchable
      />

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleUpdate}
        >
          Update
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>

    </div>
  )
}

export default PoolEdit;