import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import Season from "../types/Season";
import { SeasonOption } from "../types/SeasonOption";
import { deleteApiData, getApiData, patchApiData, postApiData } from "../utils/scorebenchApi";

class SeasonService
{
  private seasons: Season[] = [];

  getSeasons = async (leagueId: string, forceRefresh?: boolean): Promise<Season[]> =>
  {
    if (this.seasons.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/season`;

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as Season[]

      this.seasons = records;
    }

    return this.seasons
  }

  getSeason = async (leagueId: string, seasonId: string): Promise<Season | null> =>
  {
    const targetSeason = (await this.getSeasons(leagueId))
      .find(s => s.id === seasonId);

    return targetSeason ?? null;
  }

  deleteSeason = async (leagueId: string, seasonId: string): Promise<void> =>
  {
    const apiSuffix = `league/${leagueId}/season/${seasonId}`

    await deleteApiData(apiSuffix);

    this.seasons = [];
  }

  createSeason = async (newSeason: Season) =>
  {
    const apiSuffix = `league/${newSeason.leagueId}/season`

    await postApiData(apiSuffix, newSeason);

    this.seasons = [];
  }

  updateSeason = async (updatedSeason: Season) =>
  {
    const apiSuffix = `league/${updatedSeason.leagueId}/season`

    await patchApiData(apiSuffix, updatedSeason);

    this.seasons = [];
  }

  deleteSeasons = async (leagueId: string, seasonIds: string[]): Promise<void> =>
  {
    await Promise.all(seasonIds.map(async (seasonId: string) => await this.deleteSeason(leagueId, seasonId)))
  }

  getSeasonOptions = async (leagueId: string, forceRefresh?: boolean): Promise<SeasonOption[]> =>
  {
    return (await this.getSeasons(leagueId, forceRefresh))
      .map((season) =>
      {
        return {
          value: season.id,
          label: season.name
        }
      })
  }
}

export const seasonService = new SeasonService();