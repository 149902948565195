import { useEffect, useState } from "react"
import { DivisionOptions } from "../../types/DivisionOptions";
import { divisionService } from "../../services/DivisionService";
import { poolService } from "../../services/PoolService";
import RequestStatus from "../../types/RequestStatus";
import { useNavigate } from "react-router-dom";
import Pool from "../../types/Pool";

export interface UsePoolEditPageData
{
  poolName: string,
  divisionId: string,
  divisionOptions: DivisionOptions[],
  requestStatus: RequestStatus,
  handleDivisionUpdate(divisionId: string): void,
  handleNameUpdate(name: string): void,
  handleUpdate(): void,
  handleCancel(): void
}

export interface UsePoolEditPageProps
{
  leagueId?: string,
  seasonId?: string,
  poolId?: string
}

const usePoolEditPage = (props: UsePoolEditPageProps): UsePoolEditPageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [poolName, setPoolName] = useState("");
  const [divisionId, setDivisionId] = useState("");

  const [divisionOptions, setDivisionOptions] = useState<DivisionOptions[]>([]);

  const handleNameUpdate = (name: string) => setPoolName(name);

  const navigate = useNavigate();

  const handleCancel = () => navigate("/Pools");

  const handleUpdate = () =>
  {
    const updatePool = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId || !props.seasonId || !props.poolId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const updatedPool: Pool = {
          id: props.poolId,
          name: poolName,
          divisionId,
          leagueId: props.leagueId,
          seasonId: props.seasonId,
        }

        await poolService.updatePool(updatedPool);

        navigate("/Pools");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    updatePool();
  }

  const handleDivisionUpdate = (divisionId: string) =>
  {
    setDivisionId(divisionId);
  }

  const fetchDivisions = async () =>
  {
    if (!props.leagueId || !props.seasonId)
    {
      return
    }

    const divisionOptionsData = await divisionService.getDivisionOptions(props.leagueId, props.seasonId, true);

    setDivisionOptions(divisionOptionsData);
  }

  // Initialise data on load
  useEffect(() =>
  {
    const initData = async () =>
    {
      try
      {
        if (!props.leagueId || !props.seasonId || !props.poolId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setRequestStatus(RequestStatus.LOADING);

        await fetchDivisions();

        const pool = await poolService.getPool(
          props.leagueId, props.seasonId, props.poolId);

        if (!pool)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setPoolName(pool.name);
        setDivisionId(pool.divisionId);

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    initData();
  }, [])

  return {
    poolName,
    divisionId,
    divisionOptions,
    requestStatus,
    handleDivisionUpdate,
    handleNameUpdate,
    handleUpdate,
    handleCancel
  }
}

export default usePoolEditPage;