import { Button, FileInput, Grid, LoadingOverlay, Switch, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import useGameImport, { UseGameImportProps, UseGameImportData } from "./useGameImport";
import RequestStatus from "../../types/RequestStatus";

const ErrorDisplay = () =>
{
  return (
    <div className="dataWindow">
      <p className="title">IMPORT GAMES</p>
      <p>Boom</p>)
    </div>
  )
}

const GameImport: React.FC = () =>
{
  const { leagueId, seasonId } = useParams();

  const gameImportProps: UseGameImportProps = {
    leagueId: leagueId ?? "",
    seasonId: seasonId ?? ""
  }

  const gameImportData: UseGameImportData = useGameImport(gameImportProps)

  if (gameImportData.error)
  {
    return (
      <ErrorDisplay />
    )
  }

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={gameImportData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">IMPORT GAMES</p>
      <Grid>
        <Grid.Col span={2}>
          <FileInput
            placeholder="Select file"
            variant="default"
            name="file"
            accept=".csv"
            onChange={gameImportData.setImportFile}
          />
        </Grid.Col>
        <Grid.Col span="content">
          <Button variant="default" onClick={gameImportData.validateGames}>Validate</Button>
        </Grid.Col>
        <Grid.Col span="content">
          <Button variant="default" onClick={gameImportData.importGames}>Import</Button>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col mt="md">
          <Switch
            label="Create missing records"
            checked={gameImportData.createResources}
            onChange={gameImportData.handleCreateResourceSwitch}
          />
        </Grid.Col>
        <Grid.Col mb="md">
          <Switch
            label="Update existing games"
            checked={gameImportData.updateExisting}
            onChange={gameImportData.handleUpdateExistingSwitch}
          />
        </Grid.Col>
      </Grid>
      {gameImportData.gamesToImport && <Text>Games to import: {gameImportData.gamesToImport.length}</Text>}
      {gameImportData.validationMessages.map(m => <Text>{m}</Text>)}
    </div>
  )
}

export default GameImport;

