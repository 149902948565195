import { useState } from "react";
import Season from "../types/Season";
import { seasonService } from "../services/SeasonService";
import { SeasonOption } from "../types/SeasonOption";
import useLeague from "./useLeague";

export interface UseSeasonsData
{
  seasons: Season[],
  getSeason(seasonId: string): Season | undefined
  getSeasonOptions(): SeasonOption[]
  refreshSeasons(): Promise<void>,
}

const useSeasons = (): UseSeasonsData =>
{
  const leagueData = useLeague();

  const [seasons, setSeasons] = useState<Season[]>([]);

  const getSeason = (seasonId: string) => seasons.find(s => s.id === seasonId);

  const refreshSeasons = async () =>
  {
    const league = await leagueData.refreshLeague();

    const seasonData = await seasonService.getSeasons(league.id, true);

    setSeasons(seasonData);
  }

  const getSeasonOptions = (): SeasonOption[] =>
  {
    return seasons
      .map((season) =>
      {
        return {
          value: season.id,
          label: season.name
        }
      })
  }

  return {
    seasons,
    getSeason,
    getSeasonOptions,
    refreshSeasons,
  }
}

export default useSeasons;