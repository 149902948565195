import stringToColor from "../../utils/stringToColor";
import { LOGOSIZE } from "../../types/LogoSize";

import styles from "./CompetitorLogo.module.css"
import Competitor from "../../types/Competitor";
import { UseCompetitorLogoData, useCompetitorLogo } from "./useCompetitorLogo";
import Logo from "../../types/Logo";

const logoSizeMap = {
  SMALL: styles.logoSmall,
  MEDIUM: styles.logoMedium,
  LARGE: styles.logoLarge
}

const avatarSizeMap = {
  SMALL: styles.avatarSmall,
  MEDIUM: styles.avatarMedium,
  LARGE: styles.avatarLarge
}
interface CompetitorLogoProps
{
  competitor?: Competitor,
  logo?: Logo,
  size?: LOGOSIZE,
  center?: boolean
}

const CompetitorLogo = (props: CompetitorLogoProps) =>
{
  const pageData: UseCompetitorLogoData = useCompetitorLogo(
    {
      competitor: props.competitor,
      logo: props.logo
    });

  const size: LOGOSIZE = props.size ? props.size : LOGOSIZE.SMALL;
  const logoStyle: string = logoSizeMap[size]
  const avatarStyle: string = avatarSizeMap[size]
  const centerStyle: string = props.center ? "ml-auto mr-auto" : "";

  if (pageData.logo)
  {
    const logoUrl = `/${pageData.logo.id}.jpeg`

    return (
      <>
        <img className={`${logoStyle} ${centerStyle}`} src={logoUrl} onError={(event) => event.currentTarget.style.display = 'none'} />
      </>
    )
  }

  if (pageData.caption)
  {
    const backgroundColor: string = stringToColor(pageData.caption)

    return (
      <div className={`${avatarStyle} ${centerStyle}`} style={{ backgroundColor: backgroundColor }}>
        <p>{pageData.caption.substring(0, 1)}</p>
      </div>
    )
  }

  return (<div></div>)
};

export default CompetitorLogo;