import { Tooltip } from "@mantine/core";
import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./SideBarLink.module.css";

export interface ISideBarLinkProps
{
  linkIcon: React.ReactNode
  linkText: string,
  linkUrl?: string,
  linkAction?(): void,
  customCss?: string
}

const SideBarLink = (props: ISideBarLinkProps) =>
{
  const addedCss: string = props.customCss ?? ""

  if (props.linkUrl)
  {
    return (
      <Tooltip label={props.linkText} position="right" transitionDuration={0} className={addedCss}>
        <NavLink to={props.linkUrl} className={({ isActive }) => isActive ? styles.linkCardActive : styles.linkCard}>
          {props.linkIcon}
        </NavLink>
      </Tooltip>
    )
  }

  if (props.linkAction)
  {
    return (
      <Tooltip label={props.linkText} position="right" transitionDuration={0} onClick={() => props.linkAction!()} className={addedCss}>
        <div className={styles.linkCard}>
          {props.linkIcon}
        </div>
      </Tooltip>
    )
  }

  return <></>
}

export default SideBarLink;
