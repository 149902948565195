import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { divisionService } from "../../services/DivisionService";
import Division from "../../types/Division";
import RequestStatus from "../../types/RequestStatus";

export interface UseDivisionCreatePageData
{
  divisionName: string,
  requestStatus: RequestStatus,
  handleNameUpdate(name: string): void,
  handleCreate(): void,
  handleCancel(): void
}

export interface UseDivisionCreatePageProps
{
  leagueId?: string,
  seasonId?: string
}

const useDivisionCreatePage = (props: UseDivisionCreatePageProps): UseDivisionCreatePageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [divisionName, setDivisionName] = useState("");

  const navigate = useNavigate();

  const handleNameUpdate = (name: string) => setDivisionName(name);
  const handleCancel = () => navigate("/Divisions");

  const handleCreate = () =>
  {
    const createDivision = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId || !props.seasonId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const newDivision: Division = {
          id: "",
          leagueId: props.leagueId,
          seasonId: props.seasonId,
          name: divisionName
        }

        await divisionService.createDivision(newDivision);

        navigate("/Divisions");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    createDivision();
  }

  return {
    divisionName,
    requestStatus,
    handleNameUpdate,
    handleCreate,
    handleCancel
  }
}

export default useDivisionCreatePage;