import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { divisionService } from "../../services/DivisionService";
import Division from "../../types/Division";
import RequestStatus from "../../types/RequestStatus";

export interface UseDivisionEditPageData
{
  divisionName: string,
  requestStatus: RequestStatus,
  handleNameUpdate(name: string): void,
  handleUpdate(): void,
  handleCancel(): void
}

export interface UseDivisionEditPageProps
{
  leagueId?: string,
  seasonId?: string,
  divisionId?: string
}

const useDivisionEditPage = (props: UseDivisionEditPageProps): UseDivisionEditPageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [divisionName, setDivisionName] = useState("");

  const navigate = useNavigate();

  const handleNameUpdate = (name: string) => setDivisionName(name);
  const handleCancel = () => navigate("/Divisions");

  const handleUpdate = () =>
  {
    const updateDivision = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId || !props.seasonId || !props.divisionId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const updatedDivision: Division = {
          id: props.divisionId,
          leagueId: props.leagueId,
          seasonId: props.seasonId,
          name: divisionName
        }

        await divisionService.updateDivision(updatedDivision);

        navigate("/Divisions");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    updateDivision();
  }

  // Initialise data on load
  useEffect(() =>
  {
    const initData = async () =>
    {
      try
      {
        if (!props.leagueId || !props.seasonId || !props.divisionId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setRequestStatus(RequestStatus.LOADING);

        const division = await divisionService.getDivision(
          props.leagueId, props.seasonId, props.divisionId);

        if (!division)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setDivisionName(division.name);

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    initData();
  }, [])

  return {
    divisionName,
    requestStatus,
    handleNameUpdate,
    handleUpdate,
    handleCancel
  }
}

export default useDivisionEditPage;