import { Button, Modal } from "@mantine/core";

interface IDeleteConfirmationProps
{
  opened: boolean
  handleDelete(): void,
  handleClose(): void
}

const DeleteConfirmation = (props: IDeleteConfirmationProps) =>
{
  const message = "Are you sure you want to delete this record(s)?";

  return (
    <Modal opened={props.opened} onClose={props.handleClose} centered>
      <div className="flex flex-col">
        <p>{message}</p>
        <div className="flex flex-row justify-end">
          <Button variant="default" className="mt-8" onClick={props.handleDelete}>Delete</Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteConfirmation;
