export enum PenaltyCategory
{
  "MINOR" = "MINOR",
  "MAJOR" = "MAJOR",
  "MISCONDUCT" = "MISCONDUCT",
  "GAMEMISCONDUCT" = "GAMEMISCONDUCT"
}

export interface PenaltyType
{
  id: string,
  penaltyCategory: PenaltyCategory,
  name: string
}

export const penalties: PenaltyType[] = [
  {
    id: "TRIP",
    name: "Tripping",
    penaltyCategory: PenaltyCategory.MINOR
  },
  {
    id: "INTER",
    name: "Interference",
    penaltyCategory: PenaltyCategory.MINOR
  }
]

export interface PenaltyOption 
{
  value: string,
  label: string,
  group: string
}

export const getPenaltyOptions = (): PenaltyOption[] =>
{
  return penalties.map(p =>
  {
    return {
      value: p.id,
      label: p.name,
      group: p.penaltyCategory.toString()
    }
  });
};

export const getPenalty = (penaltyId: string): PenaltyType | undefined =>
{
  return penalties.find(p => p.id === penaltyId);
}