import { Button, Checkbox, LoadingOverlay, Select } from "@mantine/core";
import { Edit } from "@mui/icons-material";
import Division from "../../types/Division";
import RequestStatus from "../../types/RequestStatus";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import { Table, TableBody, TableDataCell, TableDataRow, TableHead, TableHeadCell, TableHeadRow } from "../TableComponents/TableComponents";
import useDivisionsPage, { UseDivisionsPageData } from "./useDivisionsPage"

const Divisions = () =>
{
  const pageData: UseDivisionsPageData = useDivisionsPage();

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">DIVISIONS</p>
      <div className="searchToolbar">
        <Select
          data={pageData.seasonOptions}
          value={pageData.currentSeason}
          searchable
          className="mb-4"
          onChange={pageData.handleSeasonSelect}
        />

        <Button
          variant="default"
          className="ml-4 mb-4"
          onClick={pageData.handleCreateClick}
        >
          Create
        </Button>

        <Button
          variant="default"
          className="ml-4 mb-4"
          onClick={pageData.handleSelectAll}
        >
          Select all
        </Button>

        <Button
          variant="default"
          disabled={pageData.selectedDivisions.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.handleClearSelection}
        >
          Clear selection
        </Button>

        <Button
          variant="default"
          disabled={pageData.selectedDivisions.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.showDeleteModal}
        >
          Delete selected
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell />
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Season</TableHeadCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {pageData.divisions.map((division: Division) => (
            <TableDataRow key={division.id}>
              <TableDataCell>
                <Checkbox
                  checked={pageData.selectedDivisions.includes(division.id)}
                  onChange={() => pageData.handleDivisionSelect(division.id)} />
              </TableDataCell>
              <TableDataCell>{division.name}</TableDataCell>
              <TableDataCell>{pageData.getSeasonName(division.seasonId)}</TableDataCell>
              <TableDataCell>
                <Button
                  variant="light"
                  onClick={() => pageData.handleEditClick(division)}
                >
                  <Edit />
                </Button>
              </TableDataCell>
            </TableDataRow>
          ))}
        </TableBody>
      </Table>

      {pageData.displayDeleteModal &&
        <DeleteConfirmation
          opened={pageData.displayDeleteModal}
          handleDelete={pageData.deleteSelectedDivisions}
          handleClose={pageData.closeDeleteModal}
        />}
    </div>
  )
}

export default Divisions;
