import { useState } from "react"
import RequestStatus from "../../types/RequestStatus";
import { useNavigate } from "react-router-dom";
import { logoService } from "../../services/LogoService";
import Logo from "../../types/Logo";

export interface UseLogoCreatePageData
{
  logoName: string,
  requestStatus: RequestStatus,
  handleNameUpdate(name: string): void,
  handleCreate(): void,
  handleCancel(): void
}

export interface UseLogoCreatePageProps
{
  leagueId?: string
}

const useLogoCreatePage = (props: UseLogoCreatePageProps): UseLogoCreatePageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [logoName, setLogoName] = useState("");

  const handleNameUpdate = (name: string) => setLogoName(name);

  const navigate = useNavigate();

  const handleCancel = () => navigate("/Logos");

  const handleCreate = () =>
  {
    const createLogo = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const newLogo: Logo = {
          id: "",
          name: logoName,
          leagueId: props.leagueId
        }

        await logoService.createLogo(newLogo);

        navigate("/Logos");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    createLogo();
  }

  return {
    logoName,
    requestStatus,
    handleNameUpdate,
    handleCreate,
    handleCancel
  }
}

export default useLogoCreatePage;