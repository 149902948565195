import { Button, Checkbox, LoadingOverlay, Select } from "@mantine/core";
import { Edit } from "@mui/icons-material";
import Game, { GameState } from "../../types/Game";
import RequestStatus from "../../types/RequestStatus";
import getTimeString from "../../utils/timeUtils";
import { Table, TableBody, TableDataCell, TableDataRow, TableHead, TableHeadCell, TableHeadRow } from "../TableComponents/TableComponents";
import useGamesPage, { UseGamesPageData, UseGamesPageProps } from "./useGamesPage";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import { useParams } from "react-router-dom";

const Games = () =>
{
  const { leagueId, seasonId } = useParams();

  const gamePageProps: UseGamesPageProps = {
    leagueId: leagueId ?? "",
    seasonId: seasonId ?? ""
  }

  const pageData: UseGamesPageData = useGamesPage(gamePageProps);

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">GAMES</p>
      <div className="searchToolbar">
        <Button
          variant="default"
          className="mb-4"
          onClick={() => pageData.handleCreateClick(pageData.currentSeason)}
        >
          Create
        </Button>
        <Select
          data={pageData.seasonOptions}
          value={pageData.currentSeason}
          searchable
          className="ml-4 mb-4"
          onChange={(value) => pageData.handleSeasonSelect(value)}
        />
        <Button
          variant="default"
          className="ml-4 mb-4"
          onClick={pageData.handleSelectAll}
        >
          Select all
        </Button>
        <Button
          variant="default"
          disabled={pageData.selectedGames.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.handleClearSelection}
        >
          Clear selection
        </Button>
        <Button
          variant="default"
          disabled={pageData.selectedGames.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.showDeleteModal}
        >
          Delete selected
        </Button>

        <Button
          variant="default"
          className="ml-4 mb-4"
          onClick={pageData.handleImportClick}
        >
          Import
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell />
            <TableHeadCell>#</TableHeadCell>
            <TableHeadCell>Home</TableHeadCell>
            <TableHeadCell>Away</TableHeadCell>
            <TableHeadCell>Score</TableHeadCell>
            <TableHeadCell>Time</TableHeadCell>
            <TableHeadCell>Division</TableHeadCell>
            <TableHeadCell>Pool</TableHeadCell>
            <TableHeadCell>Venue</TableHeadCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {pageData.games.map((game: Game) => (
            <TableDataRow key={game.id}>
              <TableDataCell>
                <Checkbox
                  checked={pageData.isSelected(game.id ?? "")}
                  onChange={() => pageData.handleGameSelect(game.id ?? "")}
                />
              </TableDataCell>
              <TableDataCell>{game.gameId}</TableDataCell>
              <TableDataCell>{pageData.getCompetitorReferenceText(game.competitorOne)}</TableDataCell>
              <TableDataCell>{pageData.getCompetitorReferenceText(game.competitorTwo)}</TableDataCell>
              <TableDataCell>{game.gameState === GameState.COMPLETE ? `${game.competitorOneScore} - ${game.competitorTwoScore}` : ""}</TableDataCell>
              <TableDataCell>{getTimeString(game.time)}</TableDataCell>
              <TableDataCell>{pageData.getDivisionText(game.divisionId)}</TableDataCell>
              <TableDataCell>{pageData.getPoolText(game.poolId)}</TableDataCell>
              <TableDataCell>{pageData.getLocationText(game.locationId)}</TableDataCell>
              <TableDataCell>
                <Button variant="light" onClick={() => pageData.handleEditClick(game.seasonId, game.id ?? "")}>
                  <Edit />
                </Button>
              </TableDataCell>
            </TableDataRow>
          ))}
        </TableBody>
      </Table>

      {pageData.displayDeleteModal &&
        <DeleteConfirmation
          opened={pageData.displayDeleteModal}
          handleDelete={pageData.deleteSelectedGames}
          handleClose={pageData.closeDeleteModal}
        />}

    </div>
  )
}

export default Games;
