import { useState, useRef, useEffect } from "react";
import { leagueService } from "../../services/LeagueService";
import { seasonService } from "../../services/SeasonService";
import League from "../../types/League";
import RequestStatus from "../../types/RequestStatus";
import Season from "../../types/Season";
import { handleRowSelect } from "../../utils/handlers";
import { useNavigate } from "react-router-dom";

export interface UseSeasonsPageData
{
  seasons: Season[],
  requestStatus: RequestStatus,
  selectedSeasons: string[],
  displayDeleteModal: boolean,
  handleSeasonSelect(seasonId: string): void,
  handleClearSelection(): void,
  handleCreateClick(): void,
  handleEditClick(season: Season): void,
  deleteSelectedSeasons(): Promise<void>,
  showDeleteModal(): void,
  closeDeleteModal(): void,
  isSelected(seasonId: string): boolean
}

const useSeasonsPage = (): UseSeasonsPageData =>
{
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [selectedSeasons, setSelectedSeasons] = useState<string[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);

  const league = useRef<League | null>(null);

  const navigate = useNavigate();

  const handleClearSelection = () => setSelectedSeasons([]);
  const handleCreateClick = () => navigate(`/league/${league.current?.id}/season/create`);
  const handleEditClick = (season: Season) => navigate(`/league/${league.current?.id}/season/${season.id}/edit`);
  const showDeleteModal = () => setDisplayDeleteModal(true);
  const closeDeleteModal = () => setDisplayDeleteModal(false);
  const isSelected = (seasonId: string) => selectedSeasons.includes(seasonId);

  const fetchSeasons = async () =>
  {
    if (!league.current)
    {
      return;
    }

    const seasonData = await seasonService.getSeasons(league.current.id);

    setSeasons(seasonData);
  }

  const deleteSelectedSeasons = async () =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      if (league.current?.id && selectedSeasons.length > 0)
      {
        await seasonService.deleteSeasons(league.current.id, selectedSeasons);

        setDisplayDeleteModal(false);

        await fetchSeasons();

        setSelectedSeasons([]);
      }

      setRequestStatus(RequestStatus.SUCCESS);
    }
    catch
    {
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  // Initialisation on load
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        const leagueData = await leagueService.getLeague();
        league.current = leagueData;

        await fetchSeasons();

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, [])

  return {
    seasons,
    requestStatus,
    selectedSeasons,
    displayDeleteModal,
    handleSeasonSelect: (seasonId: string) => handleRowSelect(seasonId, selectedSeasons, setSelectedSeasons),
    handleClearSelection,
    handleCreateClick,
    handleEditClick,
    deleteSelectedSeasons,
    showDeleteModal,
    closeDeleteModal,
    isSelected
  }
}

export default useSeasonsPage;