import { useContext } from 'react';
import LoginForm from './components/LoginForm/LoginForm';
import SideBar from './components/SideBar/SideBar';
import { AuthContext, IAuthContext } from './contexts/AuthContext';
import AppRoutes from './routes/AppRoutes';

function App()
{
  const authData: IAuthContext = useContext(AuthContext);

  if (!authData.user) return <LoginForm />

  return (
    <div>
      <SideBar />
      <div className="appWindow">
        <AppRoutes />
      </div>
    </div>
  );
}

export default App;
