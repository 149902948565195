import { Button, Checkbox, LoadingOverlay } from "@mantine/core";
import Season from "../../types/Season";
import RequestStatus from "../../types/RequestStatus";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";
import { Table, TableBody, TableDataCell, TableDataRow, TableHead, TableHeadCell, TableHeadRow } from "../TableComponents/TableComponents";
import useSeasonsPage, { UseSeasonsPageData } from "./useSeasonsPage";
import { Edit } from "@mui/icons-material";

const Seasons = () =>
{
  const pageData: UseSeasonsPageData = useSeasonsPage();

  return (
    <div className="dataWindow">

      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />

      <p className="title">SEASONS</p>
      <div className="searchToolbar">
        <Button
          variant="default"
          className="ml-4 mb-4"
          onClick={pageData.handleCreateClick}
        >
          Create
        </Button>

        <Button
          variant="default"
          disabled={pageData.selectedSeasons.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.handleClearSelection}
        >
          Clear selection
        </Button>

        <Button
          variant="default"
          disabled={pageData.selectedSeasons.length === 0}
          className="ml-4 mb-4"
          onClick={pageData.showDeleteModal}
        >
          Delete selected
        </Button>

      </div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell />
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell />
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {pageData.seasons.map((season: Season) => (
            <TableDataRow key={season.id}>
              <TableDataCell>
                <Checkbox
                  checked={pageData.selectedSeasons.includes(season.id)}
                  onChange={() => pageData.handleSeasonSelect(season.id)}
                />
              </TableDataCell>
              <TableDataCell>{season.name}</TableDataCell>
              <TableDataCell>
                <Button
                  variant="light"
                  onClick={() => pageData.handleEditClick(season)}
                >
                  <Edit />
                </Button>
              </TableDataCell>
            </TableDataRow>
          ))}
        </TableBody>
      </Table>

      {pageData.displayDeleteModal &&
        <DeleteConfirmation
          opened={pageData.displayDeleteModal}
          handleDelete={pageData.deleteSelectedSeasons}
          handleClose={pageData.closeDeleteModal}
        />}
    </div>
  )
}

export default Seasons;
