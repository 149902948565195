import { Button, LoadingOverlay, TextInput } from "@mantine/core";
import { useParams } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import { UseLocationEditPageData } from "./useLocationEditPage";
import useLocationEditPage from "./useLocationEditPage";

const LocationEdit: React.FC = () =>
{
  const { leagueId, locationId } = useParams();

  const pageData: UseLocationEditPageData = useLocationEditPage({ leagueId, locationId });

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">EDIT LOCATION</p>
      <TextInput
        placeholder="Location name"
        label="Location name"
        value={pageData.locationName}
        onChange={(event) => pageData.handleNameUpdate(event.currentTarget.value)}
        required
      />

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleUpdate}
        >
          Update
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>

    </div>
  )
}

export default LocationEdit;