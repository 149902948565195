import { CompetitorTextReference, CompetitorType } from "../types/CompetitorReference";
import Game, { GameState } from "../types/Game";
import { GameType } from "../types/GameType";
import { IGameImportResult } from "../types/IGameImportResult";
import { IImportGame } from "../types/IImportGame";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { StateOptions } from "../types/StateOptions";
import { deleteApiData, getApiData, patchApiData, postApiData } from "../utils/scorebenchApi";

class GameService
{
  private games: Game[] = [];

  getGames = async (leagueId: string, seasonId: string, forceRefresh?: boolean): Promise<Game[]> =>
  {
    if (this.games.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/season/${seasonId}/game`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as Game[]

      this.games = records;
    }

    return this.games;
  }

  getGame = async (leagueId: string, seasonId: string, gameId: string): Promise<Game> =>
  {
    const apiPath = `league/${leagueId}/season/${seasonId}/game/${gameId}`

    const data: IScorebenchApiResponse = await getApiData(apiPath);
    const records = data.data as Game[]

    return records[0];
  }

  deleteGame = async (leagueId: string, seasonId: string, gameId: string): Promise<void> =>
  {
    const apiSuffix = `league/${leagueId}/season/${seasonId}/game/${gameId}`

    await deleteApiData(apiSuffix);

    this.games = [];
  }

  deleteGames = async (leagueId: string, seasonId: string, gameIds: string[]): Promise<void> =>
  {
    await Promise.all(gameIds.map(async (gameId: string) => await this.deleteGame(leagueId, seasonId, gameId)))
  }

  updateGame = async (game: Game): Promise<void> =>
  {
    const apiSuffix = `league/${game.leagueId}/season/${game.seasonId}/game`

    await patchApiData(apiSuffix, game);
  }

  createGame = async (game: Game): Promise<Game> =>
  {
    const apiSuffix = `league/${game.leagueId}/season/${game.seasonId}/game`

    const data: IScorebenchApiResponse = await postApiData(apiSuffix, game);
    const newGame = data.data[0] as Game;

    return newGame;
  }

  createEmptyGame = async (leagueId: string, seasonId: string): Promise<Game> =>
  {
    const gameTemplate = this.getGameTemplate(leagueId, seasonId);

    const newGame = await this.createGame(gameTemplate);

    return newGame;
  }

  validateImportGames = async (leagueId: string, seasonId: string, games: IImportGame[], createRequiredRecords: boolean): Promise<IGameImportResult> =>
  {
    const apiSuffix = `league/${leagueId}/season/${seasonId}/game/import?createRecords=${createRequiredRecords}`

    const data: IScorebenchApiResponse = await postApiData(apiSuffix, games);
    const validationResults = data.data[0] as IGameImportResult

    return validationResults;
  }

  importGames = async (leagueId: string, seasonId: string, games: IImportGame[], createRequiredRecords: boolean, updateExisting: boolean): Promise<Game[]> =>
  {
    const apiSuffix = `league/${leagueId}/season/${seasonId}/game/import?create=true&createRecords=${createRequiredRecords}&updateExisting=${updateExisting}`

    const data: IScorebenchApiResponse = await postApiData(apiSuffix, games);
    const importedGames = data.data as Game[]

    return importedGames;
  }

  getStateOptions = (): StateOptions[] =>
  {
    return Object.keys(GameState).map((state) =>
    {
      return {
        value: state,
        label: state
      }
    });
  };

  getGameTemplate = (leagueId: string, seasonId: string): Game =>
  {
    const competitorTemplate: CompetitorTextReference = {
      type: CompetitorType.TEXT,
      text: "TBD"
    }

    const gameTemplate: Game = {
      id: "",
      gameId: "",
      competitorOne: competitorTemplate,
      competitorTwo: competitorTemplate,
      competitorOneScore: 0,
      competitorTwoScore: 0,
      competitorOnePlayers: [],
      competitorOneGoalies: [],
      competitorTwoPlayers: [],
      competitorTwoGoalies: [],
      divisionId: "",
      gameState: GameState.SCHEDULED,
      gameType: GameType.REGULAR,
      leagueId: leagueId,
      seasonId: seasonId,
      locationId: "",
      poolId: "",
      time: new Date().toISOString(),
      events: []
    }

    return gameTemplate;
  }
}

export const gameService = new GameService();