import { useState, useRef, useEffect } from "react";
import League from "../../types/League";
import Pool from "../../types/Pool";
import RequestStatus from "../../types/RequestStatus";
import Season from "../../types/Season";
import { SeasonOption } from "../../types/SeasonOption";
import { poolService } from "../../services/PoolService";
import { leagueService } from "../../services/LeagueService";
import { seasonService } from "../../services/SeasonService";
import { handleRowSelect } from "../../utils/handlers";
import { useNavigate } from "react-router-dom";
import Division from "../../types/Division";
import { divisionService } from "../../services/DivisionService";

export interface UsePoolsPageData
{
  pools: Pool[],
  currentSeason: string,
  selectedPools: string[],
  seasonOptions: SeasonOption[],
  requestStatus: RequestStatus,
  displayDeleteModal: boolean,
  getDivisonName(divisionId: string): string,
  handleSeasonSelect(seasonId: string | null): void,
  handlePoolSelect(poolId: string): void,
  handleSelectAll(): void,
  handleClearSelection(): void,
  handleCreateClick(): void,
  handleEditClick(pool: Pool): void,
  deleteSelectedPools(): Promise<void>,
  showDeleteModal(): void,
  closeDeleteModal(): void,
  isSelected(poolId: string): boolean,
}

const usePoolsPage = (): UsePoolsPageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [pools, setPools] = useState<Pool[]>([]);
  const [currentSeason, setCurrentSeason] = useState<string>("");
  const [selectedPools, setSelectedPools] = useState<string[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
  const [seasonOptions, setSeasonOptions] = useState<SeasonOption[]>([]);

  const league = useRef<League | null>(null);
  const seasons = useRef<Season[]>([]);
  const divisions = useRef<Division[]>([]);

  const navigate = useNavigate();

  const handleSeasonSelect = (poolId: string | null) => setCurrentSeason(poolId ?? "")
  const handleSelectAll = () => setSelectedPools(pools.map(p => p.id));
  const handleClearSelection = () => setSelectedPools([]);
  const handleCreateClick = () => navigate(`/league/${league.current?.id}/season/${currentSeason}/pool/create`);
  const handleEditClick = (pool: Pool) => navigate(`/league/${league.current?.id}/season/${currentSeason}/pool/${pool.id}/edit`);
  const showDeleteModal = () => setDisplayDeleteModal(true);
  const closeDeleteModal = () => setDisplayDeleteModal(false);
  const isSelected = (poolId: string) => selectedPools.includes(poolId);

  const getDivisonName = (divisionId: string) => divisions.current.find(d => d.id === divisionId)?.name ?? "Unknown";

  const fetchPools = async (leagueId: string, seasonId: string) =>
  {
    const poolsData = await poolService.getPools(leagueId, seasonId, true);

    setPools(poolsData);
  }

  const fetchDivisions = async (leagueId: string, seasonId: string) =>
  {
    const divisionData = await divisionService.getDivisions(leagueId, seasonId, true);

    divisions.current = divisionData;
  }

  const fetchSeasonOptions = async (leagueId: string) =>
  {
    const seasonOptionData = await seasonService.getSeasonOptions(leagueId);

    setSeasonOptions(seasonOptionData);
  }

  const deleteSelectedPools = async () =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      if (league.current?.id && currentSeason && selectedPools.length > 0)
      {
        await poolService.deletePools(league.current.id, currentSeason, selectedPools);

        setDisplayDeleteModal(false);

        await fetchPools(league.current.id, currentSeason);

        setSelectedPools([]);
      }

      setRequestStatus(RequestStatus.SUCCESS);
    }
    catch
    {
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  // Update pool data when season changes
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (currentSeason && league.current?.id)
        {
          await fetchDivisions(league.current.id, currentSeason);
          await fetchPools(league.current.id, currentSeason);

          setSelectedPools([]);
        }

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, [currentSeason])

  // Initialisation on page load
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        const leagueData = await leagueService.getLeague();

        league.current = leagueData;

        if (leagueData)
        {
          const seasonData = await seasonService.getSeasons(leagueData.id);

          seasons.current = seasonData;

          setCurrentSeason(leagueData.defaultSeasonId ?? "");

          await fetchSeasonOptions(leagueData.id);
        }
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, []);

  return {
    pools,
    currentSeason,
    selectedPools,
    requestStatus,
    displayDeleteModal,
    getDivisonName,
    handleSeasonSelect,
    handlePoolSelect: (poolId: string) => handleRowSelect(poolId, selectedPools, setSelectedPools),
    handleSelectAll,
    handleClearSelection,
    handleCreateClick,
    handleEditClick,
    deleteSelectedPools,
    showDeleteModal,
    closeDeleteModal,
    isSelected,
    seasonOptions
  }
}

export default usePoolsPage;