import { LoadingOverlay, TextInput, Button } from "@mantine/core";
import { useParams } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import useDivisionCreatePage, { UseDivisionCreatePageData } from "./useDivisionCreatePage";

const DivisionCreate: React.FC = () =>
{
  const { leagueId, seasonId } = useParams();

  const pageData: UseDivisionCreatePageData = useDivisionCreatePage({ leagueId, seasonId });

  return (
    <div className="dataWindow">
      <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
      <p className="title">CREATE DIVISION</p>
      <TextInput
        placeholder="Division name"
        label="Division name"
        value={pageData.divisionName}
        onChange={(event) => pageData.handleNameUpdate(event.currentTarget.value)}
        required
      />

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCreate}
        >
          Create
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>

    </div>
  )
}

export default DivisionCreate;