import { PoolOptions } from "../types/IPoolOptions";
import Pool from "../types/Pool";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { deleteApiData, getApiData, patchApiData, postApiData } from "../utils/scorebenchApi";

class PoolService
{
  private pools: Pool[] = [];

  getPools = async (leagueId: string, seasonId: string, forceRefresh?: boolean): Promise<Pool[]> =>
  {
    if (this.pools.length === 0 || forceRefresh)
    {
      const apiPath = `league/${leagueId}/season/${seasonId}/pool`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as Pool[]

      this.pools = records;
    }

    return this.pools;
  }

  getPool = async (leagueId: string, seasonId: string, poolId: string): Promise<Pool | null> =>
  {
    const targetPool = (await this.getPools(leagueId, seasonId))
      .find(p => p.id === poolId)

    return targetPool ?? null;
  }

  deletePool = async (leagueId: string, seasonId: string, poolId: string): Promise<void> =>
  {
    const apiSuffix = `league/${leagueId}/season/${seasonId}/pool/${poolId}`

    await deleteApiData(apiSuffix);

    this.pools = [];
  }

  createPool = async (newPool: Pool) =>
  {
    const apiSuffix = `league/${newPool.leagueId}/season/${newPool.seasonId}/pool`

    await postApiData(apiSuffix, newPool);

    this.pools = [];
  }

  updatePool = async (updatedPool: Pool) =>
  {
    const apiSuffix = `league/${updatedPool.leagueId}/season/${updatedPool.seasonId}/pool`

    await patchApiData(apiSuffix, updatedPool);

    this.pools = [];
  }

  deletePools = async (leagueId: string, seasonId: string, poolIds: string[]): Promise<void> =>
  {
    await Promise.all(poolIds.map(async (poolId: string) => await this.deletePool(leagueId, seasonId, poolId)))
  }

  getPoolOptions = async (leagueId: string, seasonId: string, divisionId?: string, forceRefresh?: boolean): Promise<PoolOptions[]> =>
  {
    let pools: Pool[] = await this.getPools(leagueId, seasonId, forceRefresh)

    if (divisionId)
    {
      pools = pools.filter(p => p.divisionId === divisionId);
    }

    return pools
      .map((pool) =>
      {
        return {
          value: pool.id,
          label: pool.name
        }
      })
  }
}

export const poolService = new PoolService();