import { useState } from "react"
import RequestStatus from "../../types/RequestStatus";
import { useNavigate } from "react-router-dom";
import Season from "../../types/Season";
import { seasonService } from "../../services/SeasonService";

export interface useSeasonCreatePageData
{
  seasonName: string,
  requestStatus: RequestStatus,
  handleNameUpdate(name: string): void,
  handleCreate(): void,
  handleCancel(): void
}

export interface UseSeasonCreatePageProps
{
  leagueId?: string
}

const useSeasonCreatePage = (props: UseSeasonCreatePageProps): useSeasonCreatePageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [seasonName, setSeasonName] = useState("");

  const handleNameUpdate = (name: string) => setSeasonName(name);

  const navigate = useNavigate();

  const handleCancel = () => navigate("/Seasons");

  const handleCreate = () =>
  {
    const createSeason = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const newSeason: Season = {
          id: "",
          name: seasonName,
          leagueId: props.leagueId
        }

        await seasonService.createSeason(newSeason);

        navigate("/Seasons");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    createSeason();
  }

  return {
    seasonName,
    requestStatus,
    handleNameUpdate,
    handleCreate,
    handleCancel
  }
}

export default useSeasonCreatePage;