import { useState } from "react";
import Game from "../types/Game";
import { gameService } from "../services/GameService";
import useLeague from "./useLeague";

export interface UseGamesData
{
  games: Game[]
  createGame(seasonId: string): Promise<void>
  deleteGames(seasonId: string, gameIds: string[]): Promise<void>
  refreshGames(seasonId: string): Promise<void>
}

const useGameList = (): UseGamesData =>
{
  const leagueData = useLeague();

  const [games, setGames] = useState<Game[]>([]);

  const refreshGames = async (seasonId: string) =>
  {
    const league = await leagueData.refreshLeague();

    if (!seasonId)
    {
      return;
    }
    const gameData = await gameService.getGames(league.id, seasonId, true);

    setGames(gameData);
  }

  const createGame = async (seasonId: string) =>
  {
    const league = await leagueData.refreshLeague();

    if (!seasonId)
    {
      return;
    }

    await gameService.createEmptyGame(league.id, seasonId);

    await refreshGames(seasonId);
  }

  const deleteGames = async (seasonId: string, gameIds: string[]) =>
  {
    const league = await leagueData.refreshLeague();

    if (!seasonId || gameIds.length === 0)
    {
      return;
    }

    await gameService.deleteGames(league.id, seasonId, gameIds);

    await refreshGames(seasonId);
  }

  return {
    games,
    createGame,
    deleteGames,
    refreshGames
  }
}

export default useGameList;