import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { TextInput, Button, Group, LoadingOverlay, PasswordInput, Alert } from '@mantine/core';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import RequestStatus from '../../types/RequestStatus';

const schema = z.object({
  email: z.string().email({ message: 'Invalid email' }),
  password: z.string().min(1, { message: 'You must enter a password' }),
});

const LoginForm = () =>
{
  const authData = useContext(AuthContext);

  const form = useForm({
    validate: zodResolver(schema),
    initialValues: {
      email: "",
      password: ""
    },
  });

  return (
    <>
      <LoadingOverlay visible={authData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />

      {authData.requestStatus == RequestStatus.FAILURE &&
        <Alert title="Login failed" color="red" variant="filled">
          Invalid email or password
        </Alert>
      }

      <div className="flex flex-wrap justify-center items-center h-screen">
        <form className="max-w-lg w-full" onSubmit={form.onSubmit((values) => authData.signIn(values))}>
          <p className="text-center text-4xl">SCOREBENCH</p>
          <TextInput
            label="Email"
            placeholder="example@mail.com"
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Password"
            mt="sm"
            {...form.getInputProps('password')}
          />

          <Group position="right" mt="xl">
            <Button variant="default" type="submit">Login</Button>
          </Group>
        </form>
      </div>
    </>
  );
}

export default LoginForm;