import { Button, Grid, LoadingOverlay, Select, Text, TextInput } from "@mantine/core";
import useCompetitorEditPage, { UseCompetitorEditPageData } from "./useCompetitorEditPage";
import { useParams } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import { PlayerTable } from "../PlayerTable/PlayerTable";
import { DialogButton } from "../DialogButton/DialogButton";
import AddPlayerDialog from "./AddPlayerDialog";

const CompetitorEdit: React.FC = () =>
{
  const { leagueId, seasonId, competitorId } = useParams();

  const pageData: UseCompetitorEditPageData = useCompetitorEditPage({ leagueId, seasonId, competitorId });

  return (
    <div className="dataWindow">
      <Grid>
        <Grid.Col span={4}>
          <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
          <p className="title">EDIT TEAM</p>
          <TextInput
            placeholder="Team name"
            label="Team name"
            value={pageData.competitorName}
            onChange={(event) => pageData.handleNameUpdate(event.currentTarget.value)}
            required
          />

          <Select
            placeholder="Division"
            label="Division"
            data={pageData.divisionOptions}
            value={pageData.divisionId}
            onChange={pageData.handleDivisionUpdate}
            required
            searchable
          />

          <Select
            placeholder="Pool"
            label="Pool"
            data={pageData.poolOptions}
            value={pageData.poolId}
            onChange={pageData.handlePoolUpdate}
            disabled={pageData.poolOptions.length === 0}
            clearable
          />

          <Select
            placeholder="Logo"
            label="Logo"
            data={pageData.logoOptions}
            value={pageData.logoId}
            onChange={pageData.handleLogoUpdate}
            disabled={pageData.logoOptions.length === 0}
            clearable
            searchable
          />

          <Grid mt={30}>
            <Grid.Col span="content">
              <DialogButton label="Add player">
                <AddPlayerDialog
                  addPlayerHandler={pageData.handleAddPlayer}
                  playerOptions={pageData.playerOptions}
                />
              </DialogButton>
            </Grid.Col>
            <Grid.Col span="content">
              <DialogButton label="Add goalie">
                <AddPlayerDialog
                  addPlayerHandler={pageData.handleAddGoalie}
                  playerOptions={pageData.playerOptions}
                />
              </DialogButton>
            </Grid.Col>
            <Grid.Col span="content">
              <Button
                variant="default"
                onClick={pageData.handleRemovePlayers}
              >
                Remove players
              </Button>
            </Grid.Col>
          </Grid>
          <Text size={30} mt={20}>Players</Text>
          <PlayerTable players={pageData.getCompetitorPlayers()} handleRemovePlayer={pageData.handleRemovePlayer} />
          <Text size={30} mt={20}>Goalies</Text>
          <PlayerTable players={pageData.getCompetitorGoalies()} handleRemovePlayer={pageData.handleRemoveGoalie} />
        </Grid.Col>
      </Grid>

      <div className="flex flex-row">
        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleUpdate}
        >
          Update
        </Button>

        <Button
          variant="default"
          className="mt-8 ml-4"
          onClick={pageData.handleCancel}
        >
          Cancel
        </Button>
      </div>

    </div >
  )
}

export default CompetitorEdit;