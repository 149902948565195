import { useParams } from "react-router-dom";
import useGameEdit, { UseGameEditData, UseGameEditProps } from "./useGameEdit";
import { EditDisplay } from "./EditDisplay";

const ErrorDisplay = () =>
{
  return (<p>Boom</p>)
}

const GameEdit: React.FC = () =>
{
  const { leagueId, seasonId, gameId } = useParams();

  const gameEditProps: UseGameEditProps = {
    leagueId: leagueId ?? "",
    seasonId: seasonId ?? "",
    gameId: gameId ?? ""
  }

  const gameEditData: UseGameEditData = useGameEdit(gameEditProps)

  return (
    <div className="dataWindow">
      <p className="title">SCORE GAME</p>
      {gameEditData.error ? <ErrorDisplay /> : <EditDisplay gameEditData={gameEditData} />}
    </div>
  )
}

export default GameEdit;

