import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RequestStatus from "../../types/RequestStatus";
import Player from "../../types/Player";
import { playerService } from "../../services/PlayerService";

export interface UsePlayerCreatePageData
{
  firstName: string,
  surname: string
  playerNumber: string,
  requestStatus: RequestStatus,
  handleFirstNameUpdate(name: string): void,
  handleSurnameUpdate(name: string): void,
  handleNumberUpdate(number: string): void,
  handleCreate(): void,
  handleCancel(): void
}

export interface UsePlayerCreatePageProps
{
  leagueId?: string
}

const usePlayerCreatePage = (props: UsePlayerCreatePageProps): UsePlayerCreatePageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [playerNumber, setPlayerNumber] = useState("");

  const navigate = useNavigate();

  const handleFirstNameUpdate = (name: string) => setFirstName(name);
  const handleSurnameUpdate = (name: string) => setSurname(name);
  const handleNumberUpdate = (number: string) => setPlayerNumber(number);

  const handleCancel = () => navigate("/Players");

  const handleCreate = () =>
  {
    const createPlayer = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const newPlayer: Player = {
          id: "",
          leagueId: props.leagueId,
          firstName,
          surname,
          playerNumber
        }

        await playerService.createPlayer(newPlayer);

        navigate("/Players");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    createPlayer();
  }

  return {
    firstName,
    surname,
    playerNumber,
    requestStatus,
    handleFirstNameUpdate,
    handleSurnameUpdate,
    handleNumberUpdate,
    handleCreate,
    handleCancel
  }
}

export default usePlayerCreatePage;