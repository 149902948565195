import { useEffect, useState } from "react"
import RequestStatus from "../../types/RequestStatus";
import { useNavigate } from "react-router-dom";
import Season from "../../types/Season";
import { seasonService } from "../../services/SeasonService";

export interface UseSeasonEditPageData
{
  seasonName: string,
  requestStatus: RequestStatus,
  handleNameUpdate(name: string): void,
  handleUpdate(): void,
  handleCancel(): void
}

export interface UseSeasonEditPageProps
{
  leagueId?: string,
  seasonId?: string
}

const useSeasonEditPage = (props: UseSeasonEditPageProps): UseSeasonEditPageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [seasonName, setSeasonName] = useState("");

  const handleNameUpdate = (name: string) => setSeasonName(name);

  const navigate = useNavigate();

  const handleCancel = () => navigate("/Seasons");

  const handleUpdate = () =>
  {
    const updateSeason = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId || !props.seasonId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const updatedSeason: Season = {
          id: props.seasonId,
          name: seasonName,
          leagueId: props.leagueId
        }

        await seasonService.updateSeason(updatedSeason);

        navigate("/Seasons");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    updateSeason();
  }

  // Initialise data on load
  useEffect(() =>
  {
    const initData = async () =>
    {
      try
      {
        if (!props.leagueId || !props.seasonId || !props.seasonId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setRequestStatus(RequestStatus.LOADING);

        const season = await seasonService.getSeason(
          props.leagueId, props.seasonId);

        if (!season)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setSeasonName(season.name);

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    initData();
  }, [])

  return {
    seasonName,
    requestStatus,
    handleNameUpdate,
    handleUpdate,
    handleCancel
  }
}

export default useSeasonEditPage;