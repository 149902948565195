import { Popover, Button } from "@mantine/core"

export interface DialogButtonProps
{
  label: string
  children: React.ReactNode
}

export const DialogButton = (props: DialogButtonProps) =>
{
  return (
    <>
      <Popover width={300} trapFocus position="right-end" withArrow shadow="md">
        <Popover.Target>
          <Button variant="default">{props.label}</Button>
        </Popover.Target>
        <Popover.Dropdown sx={(theme) => ({ background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white })}>
          {props.children}
        </Popover.Dropdown>
      </Popover>
    </>
  )
}