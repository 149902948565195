import { createContext, useEffect, useState } from "react";
import { auth } from "../auth/firebase";
import { signInWithEmailAndPassword, User } from "firebase/auth";
import RequestStatus from "../types/RequestStatus";

export interface IAuthContext
{
  requestStatus: RequestStatus,

  user: User | null,
  signIn(signInProps: ISignInProps): void,
  signOut(): void
}

interface ISignInProps
{
  email: string,
  password: string
}

const AuthContext = createContext<IAuthContext>(null!);

type AuthProviderProps = {
  children: React.ReactNode[] | React.ReactNode
}

function AuthProvider({ children }: AuthProviderProps)
{
  const [requestStatus, setRequestStatus] = useState(RequestStatus.NONE);
  const [user, setUser] = useState<User | null>(null);
  const signOut = async () => await auth.signOut();

  const signIn = async (signInProps: ISignInProps) =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      await signInWithEmailAndPassword(auth, signInProps.email, signInProps.password);

      setRequestStatus(RequestStatus.SUCCESS);
    }
    catch (error)
    {
      setRequestStatus(RequestStatus.FAILURE);

      console.error(error);
    }
  };

  useEffect(() =>
  {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) =>
    {
      setUser(firebaseUser);
    });

    return unsubscribe;
  }, []);

  const data: IAuthContext = {
    requestStatus,

    user,
    signIn,
    signOut
  }

  return (
    <AuthContext.Provider value={data}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
