import { Paper, Grid, Button, Text } from "@mantine/core"
import AddPlayerDialog from "../CompetitorEdit/AddPlayerDialog"
import { DialogButton } from "../DialogButton/DialogButton"
import { PlayerTable } from "../PlayerTable/PlayerTable"
import GoalScoredDialog from "../GameEdit/GoalScoredDiaglog"
import SetGoalieDialog from "../GameEdit/SetGoalieDialog"
import { PlayerOption } from "../../types/PlayerOptions"
import Player from "../../types/Player"
import { LOGOSIZE } from "../../types/LogoSize"
import CompetitorLogo from "../CompetitorLogo/CompetitorLogo"
import PenaltyDialog from "../GameEdit/PenaltyDiaglog"
import Competitor from "../../types/Competitor"
import useCompetitorPanel from "./useCompetitorPanel"

interface CompetitorPanelProps
{
  competitor: Competitor,
  players: Player[],
  goalies: Player[],
  goalie: Player | undefined,
  playerOptions: PlayerOption[],
  score: number,
  shotsFor: number,
  setGoalie(playerId: string): void,
  handleCompetitorShotFor(): void,
  handleGoalFor(playerId?: string, assistOneId?: string, assistTwoId?: string): void,
  handlePenalty(penaltyId: string, playerId?: string): void,
  handleAddPlayer(playerId: string): void,
  handleAddGoalie(goalieId: string): void,
  handleAddDefaultPlayers(): void,
  handleRemovePlayers(): void,
  handleRemovePlayer(playerId: string): void,
  handleRemoveGoalie(goalieId: string): void
}

const CompetitorPanel: React.FC<CompetitorPanelProps> = (props: CompetitorPanelProps) =>
{
  const pageData = useCompetitorPanel({ competitor: props.competitor });

  const playerIds: string[] = props.players
    .map(p => p.id ?? "")
    .filter(p => p !== "");

  const goalieIds: string[] = props.goalies
    .map(p => p.id ?? "")
    .filter(p => p !== "");

  const allPlayerIds = [...playerIds, ...goalieIds];

  return (
    <Paper shadow="xl" p="lg" withBorder>

      <CompetitorLogo
        competitor={props.competitor}
        size={LOGOSIZE.LARGE}
        center
      />

      <p className="text-4xl text-center mt-4">{pageData.competitorText}</p>
      <p className="text-2xl text-center mt-1 text-gray-500">{pageData.divisionName}</p>

      <Text size={50} align="center">{props.score}</Text>

      <Text size="sm" align="center">Shots for: {props.shotsFor}</Text>

      <p className="text-2xl mt-10 mb-5">Events</p>
      <Grid>
        <Grid.Col span="content">
          <Button
            variant="default"
            onClick={props.handleCompetitorShotFor}
          >
            Shot for
          </Button>
        </Grid.Col>
        <Grid.Col span="content">
          <DialogButton label="Goal for">
            <GoalScoredDialog
              createGoalEventHandler={props.handleGoalFor}
              playerOptions={props.playerOptions.filter(p => playerIds.includes(p.value))}
            />
          </DialogButton>
        </Grid.Col>
        <Grid.Col span="content">
          <DialogButton label="Penalty">
            <PenaltyDialog
              createPenaltyEventHandler={props.handlePenalty}
              playerOptions={props.playerOptions.filter(p => allPlayerIds.includes(p.value))}
            />
          </DialogButton>
        </Grid.Col>
      </Grid>

      <p className="text-2xl mt-10 mb-5">Players</p>
      <Grid>
        <Grid.Col span="content">
          <DialogButton label="Add player">
            <AddPlayerDialog
              addPlayerHandler={props.handleAddPlayer}
              playerOptions={props.playerOptions}
            />
          </DialogButton>
        </Grid.Col>
        <Grid.Col span="content">
          <DialogButton label="Add goalie">
            <AddPlayerDialog
              addPlayerHandler={props.handleAddGoalie}
              playerOptions={props.playerOptions}
            />
          </DialogButton>
        </Grid.Col>
        <Grid.Col span="content">
          <Button
            variant="default"
            onClick={props.handleAddDefaultPlayers}
          >
            Add default players
          </Button>
        </Grid.Col>
        <Grid.Col span="content">
          <Button
            variant="default"
            onClick={props.handleRemovePlayers}
          >
            Remove players
          </Button>
        </Grid.Col>
        <Grid.Col span="content">
          <DialogButton label="Set active goalie">
            <SetGoalieDialog
              setGoalieHandler={props.setGoalie}
              playerOptions={props.playerOptions.filter(p => goalieIds.includes(p.value))}
              goalieId={props.goalie?.id}
            />
          </DialogButton>
        </Grid.Col>
      </Grid>

      <PlayerTable players={props.players} handleRemovePlayer={props.handleRemovePlayer} />

      <p className="text-2xl mb-5">Goalies</p>
      <PlayerTable players={props.goalies} handleRemovePlayer={props.handleRemoveGoalie} height={200} />
    </Paper>
  )
}

export default CompetitorPanel;