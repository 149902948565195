import { Button, Select } from "@mantine/core";
import { PlayerOption } from "../../types/PlayerOptions";
import { useState } from "react";

interface AddPlayerDialogProps
{
  playerOptions: PlayerOption[],
  addPlayerHandler(playerId: string): void
}

const AddPlayerDialog = (props: AddPlayerDialogProps) =>
{
  const [playerId, setPlayerId] = useState<string>("");

  return (
    <>
      <Select
        data={props.playerOptions}
        searchable
        className="mb-4"
        value={playerId}
        onChange={(e) => setPlayerId(e ?? "")}
      />
      <Button
        variant="default"
        onClick={() => props.addPlayerHandler(playerId)}
      >
        Add
      </Button>
    </>
  )
}

export default AddPlayerDialog;