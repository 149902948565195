import { useParams } from "react-router-dom"
import usePlayerCreatePage, { UsePlayerCreatePageData } from "./usePlayerCreatePage";
import { LoadingOverlay, TextInput, Button, Grid } from "@mantine/core";
import RequestStatus from "../../types/RequestStatus";

const PlayerCreate: React.FC = () =>
{
  const { leagueId } = useParams();

  const pageData: UsePlayerCreatePageData = usePlayerCreatePage({ leagueId });

  return (
    <div className="dataWindow">
      <Grid>
        <Grid.Col span={2}>
          <LoadingOverlay visible={pageData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
          <p className="title">CREATE PLAYER</p>
          <TextInput
            placeholder="First name"
            label="First name"
            value={pageData.firstName}
            onChange={(event) => pageData.handleFirstNameUpdate(event.currentTarget.value)}
            required
          />

          <TextInput
            placeholder="Surname"
            label="Surname"
            value={pageData.surname}
            onChange={(event) => pageData.handleSurnameUpdate(event.currentTarget.value)}
            required
          />

          <TextInput
            placeholder="Number"
            label="Number"
            value={pageData.playerNumber}
            onChange={(event) => pageData.handleNumberUpdate(event.currentTarget.value)}
            required
          />

          <Grid ml={2}>
            <Button
              variant="default"
              className="mt-8"
              onClick={pageData.handleCreate}
            >
              Create
            </Button>

            <Button
              variant="default"
              className="mt-8 ml-4"
              onClick={pageData.handleCancel}
            >
              Cancel
            </Button>
          </Grid>

        </Grid.Col>
      </Grid>
    </div>
  )
}

export default PlayerCreate;