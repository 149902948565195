import axios, { AxiosRequestConfig } from "axios";
import { auth } from "../auth/firebase";
import IRecordType from "../types/IRecordType";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";

const scorebenchApiEndpoint: string = process.env.REACT_APP_API_ENDPOINT || "";

async function getRequestConfiguration(): Promise<AxiosRequestConfig>
{
  const userToken = await auth.currentUser?.getIdToken();

  if (userToken)
  {
    return {
      headers: {
        "Authorization": `Bearer ${userToken}`
      }
    };
  }

  return {};
}

export async function getApiData(apiPath: string, parameters?: Record<string, any>): Promise<IScorebenchApiResponse> 
{
  const finalUrl = `${scorebenchApiEndpoint}/${apiPath}`;

  const result = await axios.get(finalUrl, { params: parameters });

  return result.data as IScorebenchApiResponse;
}

export async function patchApiData(apiPath: string, body: any): Promise<IScorebenchApiResponse> 
{
  const url = `${scorebenchApiEndpoint}/${apiPath}`;

  const configuration = await getRequestConfiguration();
  const result = await axios.patch(url, body, configuration);

  return result.data as IScorebenchApiResponse;
}

export async function postApiData(apiPath: string, body: any): Promise<IScorebenchApiResponse> 
{
  const url = `${scorebenchApiEndpoint}/${apiPath}`;

  const configuration = await getRequestConfiguration();
  const result = await axios.post(url, body, configuration);

  return result.data as IScorebenchApiResponse;
}

export async function deleteApiData(apiPath: string): Promise<IScorebenchApiResponse> 
{
  const url = `${scorebenchApiEndpoint}/${apiPath}`;

  const configuration = await getRequestConfiguration();
  const result = await axios.delete(url, { ...configuration });

  return result.data as IScorebenchApiResponse;
}

export async function getRecords<T extends IRecordType>(apiSuffix: string): Promise<T[]> 
{
  const data: IScorebenchApiResponse = await getApiData(apiSuffix);
  const records = data.data as T[]

  return records;
}

export async function updateRecord<T extends IRecordType>(record: T, apiSuffix: string): Promise<void>
{
  await patchApiData(apiSuffix, record);
}

export async function createRecord<T extends IRecordType>(record: T, apiSuffix: string): Promise<void> 
{
  await postApiData(apiSuffix, record);
}