import { useEffect, useState } from "react"
import RequestStatus from "../../types/RequestStatus";
import { useNavigate } from "react-router-dom";
import { logoService } from "../../services/LogoService";
import Logo from "../../types/Logo";

export interface UseLogoEditPageData
{
  logoName: string,
  requestStatus: RequestStatus,
  handleNameUpdate(name: string): void,
  handleUpdate(): void,
  handleCancel(): void
}

export interface UseLogoEditPageProps
{
  leagueId?: string,
  logoId?: string
}

const useLogoEditPage = (props: UseLogoEditPageProps): UseLogoEditPageData =>
{
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [logoName, setLogoName] = useState("");

  const handleNameUpdate = (name: string) => setLogoName(name);

  const navigate = useNavigate();

  const handleCancel = () => navigate("/Logos");

  const handleUpdate = () =>
  {
    const updateLogo = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        if (!props.leagueId || !props.logoId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        const updatedLogo: Logo = {
          id: props.logoId,
          name: logoName,
          leagueId: props.leagueId
        }

        await logoService.updateLogo(updatedLogo);

        navigate("/Logos");

        setRequestStatus(RequestStatus.SUCCESS)
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    updateLogo();
  }

  // Initialise data on load
  useEffect(() =>
  {
    const initData = async () =>
    {
      try
      {
        if (!props.leagueId || !props.logoId)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setRequestStatus(RequestStatus.LOADING);

        const logo = await logoService.getLogo(
          props.leagueId, props.logoId);

        if (!logo)
        {
          setRequestStatus(RequestStatus.FAILURE);

          return
        }

        setLogoName(logo.name);

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    }

    initData();
  }, [])

  return {
    logoName,
    requestStatus,
    handleNameUpdate,
    handleUpdate,
    handleCancel
  }
}

export default useLogoEditPage;