import { useState, useRef, useEffect } from "react";
import { leagueService } from "../../services/LeagueService";
import { locationService } from "../../services/LocationService";
import League from "../../types/League";
import RequestStatus from "../../types/RequestStatus";
import { handleRowSelect } from "../../utils/handlers";
import Location from "../../types/Location";
import { useNavigate } from "react-router-dom";

export interface UseLocationsPageData
{
  locations: Location[],
  requestStatus: RequestStatus,
  selectedLocations: string[],
  displayDeleteModal: boolean,
  handleLocationSelect(locationId: string): void,
  handleSelectAll(): void,
  handleClearSelection(): void,
  handleCreateClick(): void,
  handleEditClick(location: Location): void,
  deleteSelectedLocations(): Promise<void>,
  showDeleteModal(): void,
  closeDeleteModal(): void,
  isSelected(locationId: string): boolean
}

const useLocationsPage = (): UseLocationsPageData =>
{
  const [locations, setLocations] = useState<Location[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NONE);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);

  const league = useRef<League | null>(null);

  const navigate = useNavigate();

  const handleSelectAll = () => setSelectedLocations(locations.map(l => l.id));
  const handleClearSelection = () => setSelectedLocations([]);
  const handleCreateClick = () => navigate(`/league/${league.current?.id}/location/create`);
  const handleEditClick = (location: Location) => navigate(`/league/${league.current?.id}/location/${location.id}/edit`);
  const showDeleteModal = () => setDisplayDeleteModal(true);
  const closeDeleteModal = () => setDisplayDeleteModal(false);
  const isSelected = (locationId: string) => selectedLocations.includes(locationId);

  const fetchLocations = async () =>
  {
    if (!league.current)
    {
      return;
    }

    const locationData = await locationService.getLocations(league.current.id);

    setLocations(locationData);
  }

  const deleteSelectedLocations = async () =>
  {
    try
    {
      setRequestStatus(RequestStatus.LOADING);

      if (league.current?.id && selectedLocations.length > 0)
      {
        await locationService.deleteLocations(league.current.id, selectedLocations);

        setDisplayDeleteModal(false);

        await fetchLocations();

        setSelectedLocations([]);
      }

      setRequestStatus(RequestStatus.SUCCESS);
    }
    catch
    {
      setRequestStatus(RequestStatus.FAILURE);
    }
  }

  // Initialisation on load
  useEffect(() =>
  {
    const updateData = async () =>
    {
      try
      {
        setRequestStatus(RequestStatus.LOADING);

        const leagueData = await leagueService.getLeague();
        league.current = leagueData;

        await fetchLocations();

        setRequestStatus(RequestStatus.SUCCESS);
      }
      catch
      {
        setRequestStatus(RequestStatus.FAILURE);
      }
    };

    updateData();
  }, [])

  return {
    locations,
    requestStatus,
    selectedLocations,
    displayDeleteModal,
    handleLocationSelect: (locationId: string) => handleRowSelect(locationId, selectedLocations, setSelectedLocations),
    handleSelectAll,
    handleClearSelection,
    handleCreateClick,
    handleEditClick,
    deleteSelectedLocations,
    showDeleteModal,
    closeDeleteModal,
    isSelected
  }
}

export default useLocationsPage;