import { useState } from "react";
import Pool from "../types/Pool";
import { poolService } from "../services/PoolService";
import useLeague from "./useLeague";

export interface UsePoolsData
{
  pools: Pool[],
  getPool(poolId: string): Pool | undefined,
  getPoolText(poolId: string): string,
  refreshPools(seasonId: string): Promise<void>
}

const usePools = (): UsePoolsData =>
{
  const leagueData = useLeague();

  const [pools, setPools] = useState<Pool[]>([]);

  const getPool = (poolId: string) => pools.find(p => p.id === poolId);

  const getPoolText = (poolId: string) => 
  {
    if (!poolId) return "";

    return getPool(poolId)?.name ?? "Unknown"
  }

  const refreshPools = async (seasonId: string) =>
  {
    const league = await leagueData.refreshLeague();

    if (!seasonId)
    {
      return;
    }

    const poolData = await poolService.getPools(league.id, seasonId, true);

    setPools(poolData);
  }

  return {
    pools,
    getPool,
    getPoolText,
    refreshPools
  }
}

export default usePools;