import { Button, Select } from "@mantine/core";
import { useState } from "react"
import { PlayerOption } from "../../types/PlayerOptions";

export interface GoalScoredDialogProps
{
  playerOptions: PlayerOption[],
  goalieId?: string,
  createGoalEventHandler(playerId?: string, assistOneId?: string, assistTwoId?: string): void
}

const GoalScoredDialog: React.FC<GoalScoredDialogProps> = (props: GoalScoredDialogProps) =>
{
  const [playerId, setPlayerId] = useState("");
  const [assistOneId, setAssistOneId] = useState("");
  const [assistTwoId, setAssistTwoId] = useState("");

  return (
    <>
      <Select
        label="Scorer"
        data={props.playerOptions}
        searchable
        clearable
        className="mb-4"
        value={playerId}
        onChange={(e) => setPlayerId(e ?? "")}
      />
      <Select
        label="First assist"
        data={props.playerOptions}
        searchable
        clearable
        className="mb-4"
        value={assistOneId}
        onChange={(e) => setAssistOneId(e ?? "")}
      />
      <Select
        label="Second assist"
        data={props.playerOptions}
        searchable
        clearable
        className="mb-4"
        value={assistTwoId}
        onChange={(e) => setAssistTwoId(e ?? "")}
      />
      <Button
        variant="default"
        onClick={() => props.createGoalEventHandler(playerId, assistOneId, assistTwoId)}
      >
        Add
      </Button>
    </>
  )
}

export default GoalScoredDialog;