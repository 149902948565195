import { LoadingOverlay, Select, Button, Grid, Text } from "@mantine/core";
import { GameState } from "../../types/Game";
import RequestStatus from "../../types/RequestStatus";
import { UseGameEditData } from "./useGameEdit";
import GameLog from "../GameLog/GameLog";
import CompetitorPanel from "../CompetitorPanel/CompetitorPanel";

export interface EditDisplayProps
{
  gameEditData: UseGameEditData;
}

export const EditDisplay: React.FC<EditDisplayProps> = ({ gameEditData }) => 
{
  return (
    <>
      <Grid gutter={100}>
        <LoadingOverlay visible={gameEditData.requestStatus === RequestStatus.LOADING} overlayBlur={2} />
        <Grid.Col span="auto">
          <CompetitorPanel
            competitor={gameEditData.competitorOne}
            players={gameEditData.getCompetitorOnePlayers()}
            goalies={gameEditData.getCompetitorOneGoalies()}
            playerOptions={gameEditData.playerOptions}
            score={gameEditData.competitorOneGoalsFor}
            setGoalie={gameEditData.setCompetitorOneGoalieId}
            shotsFor={gameEditData.competitorOneShotsFor}
            goalie={gameEditData.getCompetitorOnePlayers().find(p => p.id === gameEditData.competitorOneGoalieId)}
            handleAddDefaultPlayers={gameEditData.handleAddCompetitorOneDefaultPlayers}
            handleAddPlayer={gameEditData.handleAddCompetitorOnePlayer}
            handleAddGoalie={gameEditData.handleAddCompetitorOneGoalie}
            handleCompetitorShotFor={gameEditData.handleCompetitorOneShotFor}
            handleGoalFor={gameEditData.handleCompetitorOneGoalFor}
            handlePenalty={gameEditData.handleCompetitorOnePenalty}
            handleRemovePlayer={gameEditData.handleRemoveCompetitorOnePlayer}
            handleRemoveGoalie={gameEditData.handleRemoveCompetitorOneGoalie}
            handleRemovePlayers={gameEditData.handleRemoveCompetitorOnePlayers}
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <Text align="center" size={40}>{gameEditData.seasonName}</Text>

          <Text align="center" size={20}>{gameEditData.divisionName}</Text>

          <Text align="center" size={20}>{gameEditData.poolName}</Text>

          <Text align="center" size={20}>{gameEditData.locationName}</Text>

          <Text align="center" size={20}>{gameEditData.gameTime.toDateString()}</Text>

          <Select
            className="mt-4"
            data={gameEditData.stateOptions}
            label="Game state"
            value={gameEditData.gameState}
            onChange={(input: GameState) => gameEditData.setGameState(input)}
          />

          <p className="text-2xl mt-10 mb-5">Game log</p>
          <GameLog
            events={gameEditData.getGameEvents([])}
            deleteGameEvent={gameEditData.deleteGameEvent}
            players={[...gameEditData.getCompetitorOnePlayers(), ...gameEditData.getCompetitorTwoPlayers(), ...gameEditData.getCompetitorOneGoalies(), ...gameEditData.getCompetitorTwoGoalies()]}
          />

          <div className="flex flex-row">
            <Button
              variant="default"
              className="mt-8 ml-4"
              fullWidth
              onClick={gameEditData.handleGameUpdate}
            >
              Update
            </Button>
          </div>

        </Grid.Col>

        <Grid.Col span="auto">
          <CompetitorPanel
            competitor={gameEditData.competitorTwo}
            players={gameEditData.getCompetitorTwoPlayers()}
            goalies={gameEditData.getCompetitorTwoGoalies()}
            playerOptions={gameEditData.playerOptions}
            score={gameEditData.competitorTwoGoalsFor}
            setGoalie={gameEditData.setCompetitorTwoGoalieId}
            shotsFor={gameEditData.competitorTwoShotsFor}
            goalie={gameEditData.getCompetitorTwoPlayers().find(p => p.id === gameEditData.competitorTwoGoalieId)}
            handleAddDefaultPlayers={gameEditData.handleAddCompetitorTwoDefaultPlayers}
            handleAddPlayer={gameEditData.handleAddCompetitorTwoPlayer}
            handleAddGoalie={gameEditData.handleAddCompetitorTwoGoalie}
            handleCompetitorShotFor={gameEditData.handleCompetitorTwoShotFor}
            handleGoalFor={gameEditData.handleCompetitorTwoGoalFor}
            handlePenalty={gameEditData.handleCompetitorTwoPenalty}
            handleRemovePlayer={gameEditData.handleRemoveCompetitorTwoPlayer}
            handleRemoveGoalie={gameEditData.handleRemoveCompetitorOneGoalie}
            handleRemovePlayers={gameEditData.handleRemoveCompetitorTwoPlayers}
          />
        </Grid.Col>

      </Grid >
    </>
  )
}