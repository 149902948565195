import League from "../types/League";
import { IScorebenchApiResponse } from "../types/ScorebenchApi";
import { getApiData } from "../utils/scorebenchApi";

class LeagueService
{
  private leagueId = "e39d5578-bb7a-4fd2-b7ba-94b85f1e0601";
  private league: League | null = null;

  getLeague = async (): Promise<League> =>
  {
    if (!this.league)
    {
      const apiPath = `league/${this.leagueId}`

      const data: IScorebenchApiResponse = await getApiData(apiPath);
      const records = data.data as League[]

      this.league = records[0];
    }

    return this.league;
  }
}

export const leagueService = new LeagueService();